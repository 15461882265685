import { Formik } from "formik";
import { Button, Form, Row, message } from "antd";
import { axiosAuth } from "helpers";
import { ChildFee, DiscountConfig, getChildAdditionalFinanceConfig } from "types/rest/child";
import FeeConfigurationDetail from "../FeeConfigurationDetail/FeeConfigurationDetail";
import FeeConfigurationDiscounts from "../FeeConfigurationDiscounts/FeeConfigurationDiscounts";

type Props = {
  schoolId: number;
  selectedKidId: number;
  additionalConfigLookups: {
    discounts: DiscountConfig[];
    steamFees: ChildFee[];
  };
  revalidate: () => Promise<boolean>;
};

const AdditionalFeeConfiguration = ({
  additionalConfigLookups,
  schoolId,
  selectedKidId,
  revalidate,
}: Props) => {
  const { steamFees = [], discounts } = additionalConfigLookups;
  const initialDiscounts = {
    sibling: null,
    corporate: null,
    other: null,
  };
  const updateFeeConfiguration = async (
    values: {
      steamFeeIds: ChildFee[];
      discounts: { corporate: null | number; sibling: null | number; other: null | number };
    } & {}
  ) => {
    const { discounts, ...rest } = values;
    const newDiscountIds = Object.values(discounts).filter(item => item !== null);

    try {
      await axiosAuth.put(getChildAdditionalFinanceConfig(schoolId, selectedKidId), {
        ...rest,
        discountIds: newDiscountIds,
      });
      revalidate();
      message.success("Fee configuration updated successfully");
    } catch (e) {
      message.error("Update fee configuration failed, please try again later, or contact support.");
    }
  };

  return (
    <Formik
      initialValues={{ steamFeeIds: [], discounts: initialDiscounts }}
      enableReinitialize
      onSubmit={updateFeeConfiguration}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <FeeConfigurationDetail
            title="Steam Fees"
            data={steamFees}
            group="steamFeeIds"
            setFieldValue={setFieldValue}
          />
          <FeeConfigurationDiscounts discounts={discounts} setFieldValue={setFieldValue} />
          <Row justify="end">
            <Button
              type="primary"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
              }}
              htmlType="submit"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AdditionalFeeConfiguration;
