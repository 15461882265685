import useSWR from "swr";
import { useAntdModal } from "hooks";
import { Tax, TaxesDataFees, TaxesTabs, getDeleteTaxUrl, getTaxesFeesListUrl, getTaxesListUrl } from "types";
import { useState } from "react";
import { axiosAuth } from "helpers";
import { AddTaxModal, TaxesFeeTable, TaxesNavigation, TaxesTable } from "./components";

const Taxes = (props: any) => {
  const [pickedTab, setPickedTab] = useState(TaxesTabs.FeeTaxes);
  const schoolId = props.match.params.id;

  const [editTaxData, setEditTaxData] = useState<Tax | undefined>(undefined);

  const { data: taxesData, revalidate } = useSWR<Tax[]>(schoolId ? getTaxesListUrl(Number(schoolId)) : null);

  const {
    data: taxesFeeData,
    revalidate: revalidateTaxesData,
    isValidating,
  } = useSWR<TaxesDataFees>(schoolId ? getTaxesFeesListUrl(Number(schoolId)) : null);

  const {
    closeModal: closeAddTaxModal,
    openModal: openAddTaxModal,
    visible: addTaxModalVisible,
  } = useAntdModal();

  const handleRemoveTax = async (record: Tax) => {
    await axiosAuth.delete(getDeleteTaxUrl(Number(schoolId), record.id));
    await revalidate();
    await revalidateTaxesData();
  };

  const handleOpenAddTaxModal = (record?: Tax) => {
    setEditTaxData(record);
    setTimeout(() => {
      openAddTaxModal();
    }, 50);
  };

  const handleRevalidateTaxes = async () => {
    await revalidateTaxesData();
  };

  const clearEditTaxData = () => {
    setEditTaxData(undefined);
  };

  if (isValidating) {
    return null;
  }

  return (
    <div>
      <TaxesNavigation pickedTab={pickedTab} setPickedTab={setPickedTab} />
      {pickedTab === TaxesTabs.FeeTaxes && (
        <TaxesFeeTable
          schoolId={schoolId}
          taxesData={taxesData || []}
          taxesFeeData={taxesFeeData}
          handleRevalidate={handleRevalidateTaxes}
        />
      )}
      {pickedTab === TaxesTabs.Taxes && (
        <TaxesTable
          handleOpenAddTaxModal={handleOpenAddTaxModal}
          handleRemoveTax={handleRemoveTax}
          taxesData={taxesData || []}
        />
      )}
      <AddTaxModal
        visible={addTaxModalVisible}
        closeModal={closeAddTaxModal}
        schoolId={schoolId}
        revalidate={revalidate}
        editTaxData={editTaxData}
        clearEditTaxData={clearEditTaxData}
      />
    </div>
  );
};

export default Taxes;
