import { InputRef, Table } from "antd";
import { Spacer } from "components";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { useRef } from "react";
import { ISingleSchoolFinancialRecord } from "../../FinancialsSingleSchool";
import { getSingleSchoolTableFinancialColumns } from "../../helpers";

interface Props {
  dataSource: ISingleSchoolFinancialRecord[];
  revalidate: () => Promise<boolean>;
  schoolCurrency: string;
  schoolId?: string;
}

export const SingleSchoolFinancialsTable = ({ dataSource, schoolId, revalidate, schoolCurrency }: Props) => {
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (confirm: (param?: FilterConfirmProps) => void, clearFilters?: () => void) => {
    clearFilters && clearFilters();
    handleSearch(confirm);
  };

  return (
    <>
      <Spacer />
      <Table
        columns={getSingleSchoolTableFinancialColumns({
          dataSource,
          searchInput,
          handleSearch,
          handleReset,
          revalidate,
          schoolId,
          schoolCurrency,
        })}
        dataSource={dataSource}
      />
    </>
  );
};
