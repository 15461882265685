import { Form, Table, message } from "antd";
import { useState } from "react";
import { Spacer } from "components";
import { axiosAuth } from "helpers";
import { useHistory } from "react-router-dom";
import { getCampTableColumns } from "../../helpers/getCampTableColumns";
import { EditableCell, EmptyValuesInfo } from "screens/Financials/components";
import useSWR from "swr";
import { isErrorInfo } from "screens/Financials/Utilities/helpers";

export interface IRecord {
  id: number;
  timeName: string;
  amount: number;
}

interface Props {
  dataSource: IRecord[];
  campName: string;
  isEditMode: boolean;
  revalidate: () => Promise<boolean>;
  schoolId?: string;
}

export const CampFeesTable = ({ campName, dataSource, isEditMode, revalidate, schoolId }: Props) => {
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const { useForm } = Form;
  const [form] = useForm();
  const history = useHistory();

  const { data: schoolData } = useSWR(schoolId ? `school/${schoolId}` : null);
  const schoolCurrency = schoolData?.currency;

  const isEditing = (record: IRecord) => record.id === editingKey;

  const handleEditValue = (record: Partial<IRecord> & { id: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const handleCancel = () => {
    setEditingKey(null);
  };

  const save = async (record: IRecord) => {
    try {
      const row = (await form.validateFields()) as IRecord;
      setEditingKey(null);

      await axiosAuth.put(`school/${schoolId}/financials/settings/camps-fees/${record.id}`, {
        amount: Number(row.amount),
      });

      revalidate();

      message.success("Amount of camp fee saved");
      history.push(`/main/schools/${schoolId}/financials/campFees`);
    } catch (errInfo: unknown) {
      if (isErrorInfo(errInfo)) {
        message.error(errInfo.response?.data?.message);
      } else {
        message.error("Error occurs when saving camp fee");
      }
    }
  };

  const mergedColumns = (campName: string) => {
    const campFeesColumns = getCampTableColumns({
      campName,
      isEditMode,
      editingKey,
      isEditing,
      save,
      handleCancel,
      handleEditValue,
      schoolCurrency,
    });

    return campFeesColumns.map(col => {
      const { editable, dataIndex, title, disableNegaitiveDigits, inputType } = col;

      if (!editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: IRecord) => ({
          record,
          dataIndex,
          title,
          editing: isEditing(record),
          disableNegaitiveDigits,
          inputType,
        }),
      };
    });
  };

  const isZeroValueInTable = dataSource.find(({ amount }) => amount <= 0);

  return (
    <>
      <Form form={form} component={false}>
        {isZeroValueInTable && <EmptyValuesInfo feeType="camp" />}
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns(campName)}
          pagination={false}
        />
      </Form>
      <Spacer size={40} />
    </>
  );
};
