import { Button, Input, Space, Spin, Table, Typography } from "antd";
import { Container, DeleteButton, Spacer } from "components";
import dayjs from "dayjs";
import { SearchOutlined, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import useSWR from "swr";
import { axiosAuth } from "helpers";
import { CampTimesModal } from "../CampTimesModal/CampTimesModal";
import { InitialData } from "../TermTimesModal/TermTimesModal";
import { Time } from "types";
import { inactiveStyle } from "screens/Times/helpers";

const { Title } = Typography;

const { Column } = Table;

interface Props {
  schoolId?: string;
}

export const CampTimeTable = ({ schoolId }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<Time[]>(`school/${schoolId}/camps-time`);
  const isPending = !data && !error;

  const normalizedData = data?.map(item => {
    const {
      type,
      status,
      timeStart,
      timeEnd,
      monday,
      wednesday,
      tuesday,
      thursday,
      friday,
      saturday,
      sunday,
      ...rest
    } = item;
    const selectedDays = [];

    const selectedDaysValues: string[] = [];
    if (monday) {
      selectedDays.push("Mon");
      selectedDaysValues.push("monday");
    }
    if (tuesday) {
      selectedDays.push("Tue");
      selectedDaysValues.push("tuesday");
    }
    if (wednesday) {
      selectedDays.push("Wed");
      selectedDaysValues.push("wednesday");
    }
    if (thursday) {
      selectedDays.push("Thu");
      selectedDaysValues.push("thursday");
    }
    if (friday) {
      selectedDays.push("Fri");
      selectedDaysValues.push("friday");
    }
    if (saturday) {
      selectedDays.push("Sat");
      selectedDaysValues.push("saturday");
    }
    if (sunday) {
      selectedDays.push("Sun");
      selectedDaysValues.push("sunday");
    }
    const newItem = {
      ...rest,
      timeStart: timeStart ? dayjs(timeStart, "HH:mm:ss").format("HH:mm") : "",
      timeEnd: timeEnd ? dayjs(timeEnd, "HH:mm:ss").format("HH:mm") : "",
      status,
      days: selectedDays.length > 0 ? selectedDays.join(", ") : "(not set)",
      selectedDays: selectedDaysValues,
    };
    return newItem;
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  return (
    <>
      <Spin spinning={isPending}>
        <Container>
          <Spacer />
          <Table
            title={() => {
              return (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title
                    level={3}
                    style={{
                      color: "#E50A5C",
                      paddingBottom: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Camp times
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={normalizedData}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Name"
              key="name"
              dataIndex="name"
              render={(title: Time["name"], record: Time) => (
                <Space size="middle" style={inactiveStyle(record)}>
                  {title}
                </Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.name.localeCompare(b.name);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("name")}
            />
            <Column
              title="Days"
              key="days"
              dataIndex="days"
              render={(days: string, record: Time) => (
                <Space size="middle" style={inactiveStyle(record)}>
                  {days}
                </Space>
              )}
              width="20%"
              sortDirections={["descend"]}
              onFilter={(value, record: any) => record.days.includes(value)}
              filters={[
                { text: "Monday", value: "Mon" },
                { text: "Tuesday", value: "Tue" },
                { text: "Wednesday", value: "Wed" },
                { text: "Thursday", value: "Thu" },
                { text: "Friday", value: "Fri" },
                { text: "Saturday", value: "Sat" },
                { text: "Sunday", value: "Sun" },
              ]}
            />
            <Column
              title="Time start"
              key="timeStart"
              dataIndex="timeStart"
              render={(title: Time["timeStart"], record: Time) => (
                <Space size="middle" style={inactiveStyle(record)}>
                  {title}
                </Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return dayjs(a.timeStart, "HH:mm:ss").unix() - dayjs(b.timeStart, "HH:mm:ss").unix();
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("timeStart")}
            />
            <Column
              title="Time end"
              key="timeEnd"
              dataIndex="timeEnd"
              render={(timeEnd: string, record: Time) => (
                <Space size="middle" style={inactiveStyle(record)}>
                  {timeEnd}
                </Space>
              )}
              width="20%"
            />
            <Column
              title=""
              width="20%"
              render={data => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setInitialData(data);
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.delete(`school/${schoolId}/camps-time/${data.id}`);
                        revalidate();
                      }}
                      type="icon"
                      showBackendError
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <CampTimesModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        schoolId={schoolId}
        afterSubmit={revalidate}
      />
    </>
  );
};
