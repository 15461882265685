import { Button, Card, Space, Spin, Table, Typography, Input, InputRef } from "antd";
import dayjs from "dayjs";
import { EditTwoTone, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { DeleteButton } from "components";
import { axiosAuth } from "helpers";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { CampRequest } from "types/rest";
import { useRef } from "react";
import { sortDates } from "helpers/sortDates";
const { Title } = Typography;
type Time = any;

type Props = RouteComponentProps<{ id?: string }, any, { id?: string }>;

const CampsList = ({ match, location }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const searchInput = useRef<null | InputRef>(null);

  const schoolId = match.params.id ?? location.state?.id;

  const { data: camps, error: campsError, revalidate } = useSWR<CampRequest[]>(`camps/school/${schoolId}`);

  const isPending = !schoolId || !camps;

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  return (
    <Spin spinning={isPending}>
      <Card>
        <Table
          title={() => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Title
                  level={3}
                  style={{
                    color: "#E50A5C",
                    paddingBottom: "0px",
                    marginBottom: "0px",
                  }}
                >
                  Camps
                </Title>
                <Button
                  color="#BDD000"
                  type="primary"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    history.push(`${pathname}/addCamp`);
                  }}
                >
                  Add new
                </Button>
              </div>
            );
          }}
          dataSource={camps}
          pagination={{
            pageSize: 20,
          }}
        >
          <Column
            title="Name"
            key="name"
            dataIndex="name"
            render={(title: Time["name"]) => <Space size="middle">{title}</Space>}
            width="50%"
            sortDirections={["descend"]}
            sorter={(a, b) => {
              return a.name.localeCompare(b.name);
            }}
            {...getColumnSearchProps("name")}
          />
          <Column
            title="Start date"
            key="startAt"
            dataIndex="startAt"
            render={(startAt: Date) => (
              <Space size="middle">{startAt ? `${dayjs(startAt).format("MMM DD,YYYY")}` : "(not set)"}</Space>
            )}
            width="20%"
            sorter={(a, b) => {
              return sortDates(a.startAt, b.startAt);
            }}
            sortDirections={["descend"]}
            {...getColumnSearchProps("startAt")}
          />
          <Column
            title="End date"
            key="endAt"
            dataIndex="endAt"
            render={(endAt: Date) => (
              <Space size="middle">{endAt ? `${dayjs(endAt).format("MMM DD,YYYY")}` : "(not set)"}</Space>
            )}
            width="20%"
            sorter={(a, b) => {
              return sortDates(a.startAt, b.startAt);
            }}
            sortDirections={["descend"]}
            {...getColumnSearchProps("endAt")}
          />

          <Column
            title=""
            width="20%"
            render={data => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="text"
                    icon={<EditTwoTone twoToneColor="#00A1E1" />}
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      history.push(`/main/schools/${schoolId}/camps/edit/${data.id}`);
                    }}
                  />
                  <DeleteButton
                    onSubmit={async () => {
                      await axiosAuth.delete(`camps/${data.id}`);
                      await revalidate();
                    }}
                    showBackendError
                    type="icon"
                  />
                </div>
              );
            }}
          />
        </Table>
      </Card>
    </Spin>
  );
};

export default CampsList;
