const countWeeksBetweenTwoDates = (date1: Date, date2: Date) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  const oneWeekTime = 1000 * 60 * 60 * 24 * 7;
  const diffTime = Math.abs(secondDate.getTime() - firstDate.getTime());
  const weeks = Math.ceil(diffTime / oneWeekTime);

  return weeks;
};

export default countWeeksBetweenTwoDates;
