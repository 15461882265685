import { FC, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  UploadFile,
} from "antd";

import { Spacer } from "components";
import { ErrorMessage } from "enums";
import { TokenCard } from "../components";
import getImageBase64 from "helpers/getImageBase64";
import { axiosAuth } from "helpers";
import { languages } from "../const";
import { DevelopmentSubCategoryType } from "./SubCategory";

const { Title } = Typography;

export type InitialData = Record<string, any>;

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: DevelopmentSubCategoryType | null;
  revalidate: () => Promise<boolean>;
  subCategoryId?: number;
};

const SubCategoryModal: FC<Props> = ({ isVisible, onClose, initialData, revalidate, subCategoryId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState("en");
  const [form] = Form.useForm();

  const initialDataId = initialData?.id;

  const properSubCategoryLangData = initialData?.developmentSubCategoryLang.find(
    ({ lang }) => lang === activeLanguage
  );

  const parsedInitialData = {
    ...initialData,
    ...properSubCategoryLangData,
  };

  const title = parsedInitialData?.name ? "Update skill name" : "Create skill name";

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  });

  const handleOnClose = () => {
    onClose();
    form.resetFields();
  };

  const onFinish = async (values: IFormValues) => {
    try {
      setIsLoading(true);

      const {
        badgePartialIconImageNew,
        badgeCompleteIconImageNew,
        badgePartialImageNew,
        badgeCompleteImageNew,
      } = values;

      const badgePartialIconImageBase64 =
        badgePartialIconImageNew && (await getImageBase64(badgePartialIconImageNew));
      const badgeCompleteIconImageBase64 =
        badgeCompleteIconImageNew && (await getImageBase64(badgeCompleteIconImageNew));
      const badgePartialImageBase64 = badgePartialImageNew && (await getImageBase64(badgePartialImageNew));
      const badgeCompleteImageBase64 = badgeCompleteImageNew && (await getImageBase64(badgeCompleteImageNew));

      const subCategoryBody = {
        ...values,
        badgePartialIconImage: badgePartialIconImageBase64,
        badgeCompleteIconImage: badgeCompleteIconImageBase64,
        badgePartialImage: badgePartialImageBase64,
        badgeCompleteImage: badgeCompleteImageBase64,
        lang: activeLanguage,
        badgePartialIconImageNew: undefined,
        badgeCompleteIconImageNew: undefined,
        badgePartialImageNew: undefined,
        badgeCompleteImageNew: undefined,
      };

      const { name } = parsedInitialData;

      if (activeLanguage !== "en" || name) {
        await axiosAuth.put(`development-sub-category/${initialDataId}`, subCategoryBody);
      } else {
        await axiosAuth.post(`development-sub-category/${subCategoryId}`, subCategoryBody);
      }

      await revalidate();
      message.success(`${title} successfully`);
      form.resetFields();
      handleOnClose();
    } catch (error) {
      message.error(`Error occured while ${title}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          {title}
        </Title>
      }
      width={800}
      open={isVisible}
      onCancel={onClose}
      footer={false}
    >
      {initialData && (
        <>
          <Row justify="end">
            {languages.map(({ label, value }) => (
              <Button
                key={value}
                type={value === activeLanguage ? "primary" : "text"}
                style={
                  value === activeLanguage
                    ? {
                        backgroundColor: "#E50A5C",
                        border: "none",
                        color: "white",
                      }
                    : {
                        color: "#E50A5C",
                      }
                }
                onClick={() => setActiveLanguage(value)}
              >
                {label}
              </Button>
            ))}
          </Row>
          <Spacer />
        </>
      )}
      <Form
        name="basic"
        initialValues={parsedInitialData ? parsedInitialData : {}}
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" name="status" labelCol={{ span: 24 }}>
              <Select>
                <Select.Option value={10}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Token complete title"
              name="badgeCompleteTitle"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Token Partial Title"
              name="badgePartialTitle"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Token Partial Description"
              name="badgePartialDescription"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Token Complete Description"
              name="badgeCompleteDescription"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<label style={{ fontSize: "1.4rem" }}>Rank</label>}
              name="rank"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <InputNumber type="number" controls={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <TokenCard
            revalidate={revalidate}
            title="Token partial icon"
            fieldValue="badgePartialIcon"
            newImageFormName="badgePartialIconImageNew"
            existingSkillId={initialDataId}
          />
          <TokenCard
            revalidate={revalidate}
            title="Token complete icon"
            fieldValue="badgeCompleteIcon"
            newImageFormName="badgeCompleteIconImageNew"
            existingSkillId={initialDataId}
          />
          <TokenCard
            revalidate={revalidate}
            title="Token partial image"
            fieldValue="badgePartialImage"
            newImageFormName="badgePartialImageNew"
            existingSkillId={initialDataId}
          />
          <TokenCard
            revalidate={revalidate}
            title="Token complete image"
            fieldValue="badgeCompleteImage"
            newImageFormName="badgeCompleteImageNew"
            existingSkillId={initialDataId}
          />
        </Row>
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{
              marginTop: 14,
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubCategoryModal;

export interface ISubCategoryData {
  id: number;
  categoryId: number;
  rank: number;
  badgePartialIcon: string;
  badgePartialImage: string;
  badgeCompleteIcon: string;
  badgeCompleteImage: string;
  status: number;
  createdAt: number;
  createdBy: number;
  updatedAt: number;
  updatedBy: number;
  developmentSubCategoryLang: ISubCategoryLang[];
}

export interface ISubCategoryLang {
  id: number;
  developmentSubCategoryId: number;
  lang: string;
  name: string;
  badgePartialTitle: string;
  badgePartialDescription: string;
  badgeCompleteTitle: string;
  badgeCompleteDescription: string;
}

export interface IFormValues {
  name: string;
  badgePartialIconImageNew: UploadFile<any>[];
  badgeCompleteIconImageNew: UploadFile<any>[];
  badgePartialImageNew: UploadFile<any>[];
  badgeCompleteImageNew: UploadFile<any>[];
  status: number;
  badgeCompleteTitle: string;
  badgePartialTitle: string;
  badgePartialDescription: string;
  rank: number;
}
