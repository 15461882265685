export const getUniquePartner = <T extends { partners: string[] }>(data: T[]): string[] => {
  const groups = new Set<string>();

  for (const item of data) {
    for (const partner of item.partners) {
      groups.add(partner);
    }
  }

  return Array.from(groups);
};
