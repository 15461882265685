import { Button, Popover } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useState } from "react";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { firstDayOfMonth, currentDate, dateFormat } from "screens/Invoice/const";

const { RangePicker } = DatePicker;

interface Props {
  handleChangeRangeDate: (dateValues: [string, string]) => void;
}

export const SummaryFilter = ({ handleChangeRangeDate }: Props) => {
  const [clicked, setClicked] = useState(false);

  const handleClickChange = (open: boolean) => {
    setClicked(open);
  };

  const handleCloseClick = () => {
    setClicked(false);
  };

  const onChangeDate: RangePickerProps["onChange"] = (date, dateString) => {
    handleChangeRangeDate(dateString);
  };

  const content = (
    <ContentContainer>
      <RangePicker
        onChange={onChangeDate}
        defaultValue={[moment(firstDayOfMonth, dateFormat), moment(currentDate, dateFormat)]}
      />
      <Button onClick={handleCloseClick} style={{ marginTop: 12 }}>
        Close
      </Button>
    </ContentContainer>
  );

  const filterTitle = <span>Filter</span>;

  return (
    <FilterContainer>
      <Popover
        placement="bottom"
        title={filterTitle}
        content={content}
        trigger="click"
        open={clicked}
        onOpenChange={handleClickChange}
      >
        <FilterOutlined />
        <span>Filter by</span>
      </Popover>
    </FilterContainer>
  );
};

const FilterContainer = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;

  span {
    margin-left: 4px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
