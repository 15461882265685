//Temporary in future languages will be dynamic from poeditor
export const languages = [
  { value: "en", label: "English", required: true },
  { value: "fr", label: "French", required: false },
  { value: "ar", label: "Arabic", required: false },
  { value: "vi", label: "Vietnamese", required: false },
  { value: "id", label: "Indonesia", required: false },
  { value: "az", label: "Azerbaijani", required: false },
  { value: "ru", label: "Russian", required: false },
  { value: "ro", label: "Romanian", required: false },
];
