enum EmployeeRole {
  SECURITY = 10,
  CLEANER = 20,
  NURSE = 30,
  NANNY = 40,
  ASSISTANT_TEACHER = 60,
  TEACHER = 70,
  HEAD_TEACHER = 80,
  ADMINISTRATOR = 90,
  SUPER_ADMIN = 100,
  TERRITORY_OWNER = 110,
  TERRITORY_MANAGER = 120,
  SCHOOL_MANAGER = 130,
  SCHOOL_ADMINISTRATOR = 140,
  PARTNER = 150,
}

export default EmployeeRole;
