import { EmployeeRole } from "enums";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { FinancialMultiSchool } from "screens/Invoice/FinancialsMutliSchool/FinancialMutliSchool";
import { FinancialsSupra } from "screens/Invoice/FinancialsSupra/FinancialsSupra";
import { useAppSelector } from "store";

export const FinancialRoute = ({ ...rest }: RouteProps) => {
  const { role } = useAppSelector(store => store.user);

  return (
    <Route
      {...rest}
      render={props =>
        role === EmployeeRole.SUPER_ADMIN ? (
          <FinancialsSupra />
        ) : role === EmployeeRole.PARTNER ? (
          <FinancialMultiSchool />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};
