import { message } from "antd";
import { axiosAuth } from "helpers";
import useSWR from "swr";
import { getAddAdditionalFeeToStatement, getChildAdditionalFinanceConfigPreview } from "types/rest/child";

const useAdditionalFees = ({ schoolId, childId }: { schoolId: number; childId: number }) => {
  const { data, revalidate } = useSWR(getChildAdditionalFinanceConfigPreview(schoolId, childId));

  const addAdditionalFeeToStatement = async () => {
    try {
      await axiosAuth.post(getAddAdditionalFeeToStatement(schoolId, childId));
      await revalidate();
      message.success("Additional fee added to statement.");
    } catch (e) {
      message.error("Failed to add additional fee to statement, please try again, or contact support.");
    }
  };

  const { financeConfigLookups, statementPreview } = data || {};

  return {
    additionalConfigLookups: financeConfigLookups,
    additionalStatementPreview: statementPreview,
    revalidateAdditionalConfig: revalidate,
    addAdditionalFeeToStatement,
  };
};

export default useAdditionalFees;
