import { Button, Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { getChildPaymentsUrl, IChildPaymentBody, PaymentCategoryTypeDto, paymentMethod } from "types";

interface IProps {
  schoolId?: string;
  kidId?: string;
  onFinish: () => void;
}

const AddPaymentModal = ({ schoolId, kidId, onFinish }: IProps) => {
  const [form] = useForm();
  const firstDayOfTheMonth = dayjs().startOf("month");
  const endDayOfTheMonth = dayjs().endOf("month");

  const handleSubmit = async (values: IChildPaymentBody) => {
    const { amount, paymentDate, paymentMethod, reference } = values;

    const requestBody: IChildPaymentBody = {
      amount: Number(amount),
      paymentDate: dayjs(paymentDate).toDate(),
      paymentMethod,
      reference,
      type: PaymentCategoryTypeDto.PAYMENT,
    };

    try {
      if (!schoolId || !kidId) return;

      await axiosAuth.post(getChildPaymentsUrl(schoolId, kidId), requestBody);
      form.resetFields();
      message.success("Payment has been saved");
      onFinish();
    } catch (err: any) {
      message.error("Something went wrong");
      form.resetFields();
    }
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row>
        <Col span={10}>
          <Form.Item
            name="paymentDate"
            label="Date: "
            labelCol={{ span: 10 }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <DatePicker
              placeholder=""
              style={{ width: "100%" }}
              disabledDate={date =>
                dayjs(date.format()).isBefore(firstDayOfTheMonth) ||
                dayjs(date.format()).isAfter(endDayOfTheMonth)
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="paymentMethod"
            label="Method: "
            labelCol={{ span: 10 }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <Select
              showSearch
              options={paymentMethod}
              filterOption={(input, option) =>
                (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={10}>
          <Form.Item
            name="amount"
            label="Amount?: "
            labelCol={{ span: 10 }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <Input placeholder="amount" type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="reference"
            label="Reference: "
            labelCol={{ span: 10 }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <Input placeholder="reference" type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="text"
                htmlType="submit"
                style={{
                  backgroundColor: "#BDD000",
                  borderColor: "#BDD000",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddPaymentModal;
