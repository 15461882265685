import { Button, Col, Form, Row, Typography, message } from "antd";
import { Spacer } from "components/UI";
import { FC, useState } from "react";
import { DownloadOutlined, EditTwoTone, FileOutlined } from "@ant-design/icons";
import { DocumentType } from "screens/Documents/Documents";
import DocumentModal, { InitialData } from "screens/Documents/DocumentModal";
import Text from "antd/lib/typography/Text";
import { useForm } from "antd/lib/form/Form";
import { axiosAuth } from "helpers";
import { allDocumentTypes } from "const";
import DeleteButton from "components/DeleteButton";
import { SelectDocument } from "./components";

const { Title } = Typography;

type Props = {
  documentsTypes: {
    label: string;
    value: number;
  }[];
  documentsTypesSelect: {
    label: string;
    value: number;
  }[];
  documentsList: DocumentType[];
  mandatoryDocuments?: {
    label: string;
    value: number;
  }[];
  hasMandatoryEducationDoc?: boolean;
  uploadId: number;
  userType: string;
  afterSubmit: () => Promise<void | boolean>;
};

const Documents: FC<Props> = ({
  documentsTypes,
  documentsTypesSelect,
  documentsList,
  uploadId,
  userType,
  afterSubmit,
  mandatoryDocuments = [],
  hasMandatoryEducationDoc = true,
}) => {
  const [isUploadDocumentPending, setIsUploadDocumentPending] = useState(false);
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);

  const documentValues: Record<number, string> = documentsTypes.reduce((prev, next) => {
    return {
      ...prev,
      [next.value]: next.label,
    };
  }, {});

  const withMandatoryDocuments = mandatoryDocuments.length > 0 || !hasMandatoryEducationDoc;

  const handleSubmit = async (values: any) => {
    try {
      setIsUploadDocumentPending(true);
      const promises = values.documents.map(async (item: any) => {
        let bodyFormData = new FormData();
        bodyFormData.append("document", item.originFileObj);
        bodyFormData.append("type", `${item.documentType}`);
        bodyFormData.append("status", "10");
        bodyFormData.append("fileName", item.name);
        bodyFormData.append(`${userType}Id`, `${uploadId}`);
        return await axiosAuth.post("document/create", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      });
      await Promise.all(promises);
      await afterSubmit();
      setTimeout(() => {
        form.resetFields();
      }, 0);

      message.success("documents added successfully");
    } catch (error) {
      message.error("Error when adding documents");
    } finally {
      setIsUploadDocumentPending(false);
    }
  };

  return (
    <>
      <Form
        form={form}
        autoComplete="off"
        onFinish={handleSubmit}
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          overflow: "hidden",
          boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
          padding: "24px",
        }}
      >
        <Title level={4} style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
          Documents
        </Title>
        {withMandatoryDocuments && (
          <>
            <Spacer size={10} />
            <Text style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
              You must upload these scanned documents before you can assign your teacher or assistant teacher
              to a classroom
            </Text>
          </>
        )}

        <Spacer size={40} />
        {withMandatoryDocuments && (
          <>
            <Row>
              {mandatoryDocuments.map(({ value }) => (
                <>
                  <Col span={15}>{documentValues[value]}</Col>
                  <Col span={9}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Text style={{ color: "#E50A5C" }}>Required*</Text>
                    </div>
                  </Col>
                </>
              ))}
              {!hasMandatoryEducationDoc && (
                <>
                  <Col span={15}> Highest Degree or Certificate </Col>
                  <Col span={9}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Text style={{ color: "#E50A5C" }}>Required*</Text>
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <Spacer />
          </>
        )}
        <Row>
          {documentsList.map(({ fileName, id, type, status, file }) => (
            <>
              <Col span={15}>{documentValues[type]}</Col>
              <Col span={9}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "200px",
                    }}
                  >
                    <FileOutlined style={{ marginRight: "5px" }} />
                    <Text ellipsis={{ tooltip: true }} style={{ width: "100%" }}>
                      {fileName}
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <a
                      rel="noreferrer"
                      href={file}
                      target="_blank"
                      style={{
                        marginRight: "20px",
                        color: "#00A1E1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.6rem",
                      }}
                    >
                      <DownloadOutlined />
                    </a>
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        const initialData: InitialData = {
                          status,
                          type: type.toString(),
                          userType,
                          uploadId,
                          documentId: id,
                        };
                        setInitialData(initialData);
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <DeleteButton
                      type="icon"
                      onSubmit={async () => {
                        await axiosAuth.get(`document/delete/${id}`);
                        await afterSubmit();
                      }}
                    />
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
        <Spacer />
        <SelectDocument documentsTypes={documentsTypesSelect} />
        <Row justify="end">
          <Form.Item>
            <Button
              loading={isUploadDocumentPending}
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
              }}
            >
              Save new files
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <DocumentModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        afterSubmit={afterSubmit}
      />
    </>
  );
};
export default Documents;
