import { Checkbox, Modal } from "antd";
import { useEffect } from "react";
import WarningIcon from "./warningIcon.png";
import { useAntdModal } from "hooks";
import Title from "antd/lib/typography/Title";
import useSWR from "swr";
import { ITermFeesData } from "screens/Financials/TermFees/TermFees";
import { SchoolData } from "screens/AddSchool/types/AddSchool.types";
import { SchoolFinancialSetting, Time, getRequiredSettingsUrl } from "types";
import { useHistory } from "react-router-dom";

const InformationInfo = ({
  label,
  isFilled,
  navigate,
}: {
  label: string;
  isFilled: boolean;
  navigate: () => void;
}) => (
  <div onClick={navigate} style={{ margin: "8px 0", cursor: "pointer" }}>
    <Checkbox checked={isFilled} style={{ marginRight: 8 }} />
    {label}
  </div>
);

const FinancialCheckInformation = ({ schoolId }: { schoolId: number }) => {
  const { visible, closeModal, openModal } = useAntdModal();
  const history = useHistory();

  const { data: termFeesData, error: termError } = useSWR<ITermFeesData[]>(
    `school/${schoolId}/financials/settings/term-fees`
  );

  const { data: schoolData, error: schoolError } = useSWR<SchoolData>(schoolId ? `school/${schoolId}` : null);

  const { data: timesData, error: timesError } = useSWR<Time[]>(`school/times/${schoolId}`);

  const timesDataFilled = timesData && timesData.length > 0;

  const schoolInformationFilled =
    !!schoolData &&
    !!schoolData.name &&
    schoolData.schoolInvoiceDetails?.royalty > 0 &&
    !!schoolData.schoolInvoiceDetails.bankAddress &&
    !!schoolData.schoolInvoiceDetails.schoolBankName &&
    !!schoolData.schoolInvoiceDetails.address &&
    !!schoolData.schoolInvoiceDetails.accountNumber &&
    !!schoolData.currency &&
    !!schoolData.country;

  const termsDataFilled = termFeesData && termFeesData.length > 0;

  const navigateTo = (path: string) => {
    history.replace(path);
  };

  const schoolFinancialKey = `schoolModalShown-${schoolId}`;

  const financialsModalShown = localStorage.getItem(schoolFinancialKey);

  const { data: schoolFinancialSettings, error: schoolFinancialError } = useSWR<SchoolFinancialSetting>(
    schoolId ? getRequiredSettingsUrl(String(schoolId)) : null
  );

  const holidaysFilled = !!schoolFinancialSettings && schoolFinancialSettings?.holidays > 0;

  useEffect(() => {
    const handleModalShown = () => {
      if (!schoolId) return;
      if (!Boolean(financialsModalShown) || financialsModalShown === null) {
        localStorage.setItem(schoolFinancialKey, "true");
        if (visible) return;
        openModal();
      }
    };
    handleModalShown();
  }, [financialsModalShown, openModal, schoolId, schoolFinancialKey, visible]);

  if (termError || schoolError || timesError || schoolFinancialError) {
    return null;
  }

  if (!termFeesData || !schoolData || !timesData || !schoolFinancialSettings) {
    return null;
  }

  if (schoolInformationFilled && termsDataFilled && timesDataFilled && holidaysFilled) {
    return null;
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginLeft: 8 }} onClick={openModal}>
        <p style={{ color: "red", fontSize: 16, marginRight: 8 }}>School Setup Incorrect</p>
        <img src={WarningIcon} width={30} height={30} alt="warning" />
      </div>
      {visible && (
        <Modal
          open={visible}
          onOk={closeModal}
          onCancel={closeModal}
          title="Missing Financials in School Set Up"
          cancelText="Close"
        >
          <div>
            <div style={{ marginBottom: 8 }}>
              <Title level={5}>
                After you finish the financial setup this notification will disappear and you will be able to
                continue
              </Title>{" "}
              You can always come back to this page to see what is missing. You can click on each item below
              to go directly to the section where you need to take action.
            </div>
            <InformationInfo
              label={
                schoolInformationFilled
                  ? "School Details have been filled correctly"
                  : "You have to fill all the required fields in the School Details page"
              }
              isFilled={schoolInformationFilled}
              navigate={() => navigateTo(`/main/schools/${schoolId}/details`)}
            />
            <InformationInfo
              label={
                holidaysFilled
                  ? "Holidays and Closures completed correctly"
                  : "You have to complete Holiday and Closure dates"
              }
              isFilled={holidaysFilled}
              navigate={() => navigateTo(`/main/schools/${schoolId}/vacation`)}
            />
            <InformationInfo
              label={
                !!timesDataFilled
                  ? "Days and Times completed correctly"
                  : "Days and Times have to be completed"
              }
              isFilled={!!timesDataFilled}
              navigate={() => navigateTo(`/main/schools/${schoolId}/times`)}
            />
            <InformationInfo
              label={!!termsDataFilled ? "Terms completed correctly" : "You have to enter the correct Terms"}
              isFilled={!!termsDataFilled}
              navigate={() => navigateTo(`/main/schools/${schoolId}/v2/terms`)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default FinancialCheckInformation;
