import { ColumnsType } from "antd/lib/table";
import { getUniquePartner, getUniqueSchool } from "../../helpers";
import { IGetColumnSearch } from "../../helpers/getColumnSearch";
import { ISchoolBalance } from "../FinancialsSupra";
import kidImg from "../../../../img/placeholder/kid.png";
import { Link } from "react-router-dom";
import { AddSupraPayment } from "../components";
import getSummaryItemColor from "screens/Kid/Financials/helpers/getSummaryItemColor";
import { invoiceCurrency } from "screens/Invoice/const";
import { Tag } from "antd";

interface IGetInvoiceTableColumns extends Omit<IGetColumnSearch, "dataIndex"> {
  dataSource: ISchoolBalance[];
  revalidate: () => Promise<boolean>;
}

export const getSupraInvoiceTableColumns = (args: IGetInvoiceTableColumns): ColumnsType<ISchoolBalance> => {
  return [
    {
      title: "School",
      dataIndex: "schoolName",
      filters: getUniqueSchool(args.dataSource).map(schoolName => ({ text: schoolName, value: schoolName })),
      filterSearch: true,
      onFilter: (value, record) => record.schoolName.includes(value.toString()),
      sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
      render: (_: any, { schoolImage, schoolName, schoolId }) => {
        return (
          <Link to={`/main/schools/${schoolId}/kids`} style={{ color: "#000", whiteSpace: "nowrap" }}>
            <img
              style={{
                width: 20,
                height: 20,
                borderRadius: "5%",
                marginRight: 10,
                position: "relative",
                top: 4,
              }}
              src={schoolImage ? schoolImage : kidImg}
              alt="avatar"
            />
            {schoolName}
          </Link>
        );
      },
    },
    {
      title: "Partner",
      dataIndex: "partners",
      filters: getUniquePartner(args.dataSource).map(partner => ({ text: partner, value: partner })),
      filterSearch: true,
      onFilter: (value, { partners }) => partners.includes(value.toString()),
      render: (_: any, { partners }) => (
        <>
          {partners.map(partner => {
            return (
              <Tag style={{ marginBottom: 4 }} key={partner}>
                {partner}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Last payment",
      dataIndex: "lastPayment",
      sorter: (a, b) => a.lastPayment - b.lastPayment,
      render: (_: any, { lastPayment }) =>
        lastPayment && (
          <span style={{ color: getSummaryItemColor(lastPayment), whiteSpace: "nowrap" }}>
            {lastPayment.toFixed(2)} {invoiceCurrency}
          </span>
        ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      sorter: (a, b) => a.balance - b.balance,
      render: (_: any, { balance }: ISchoolBalance) =>
        balance && (
          <span style={{ color: getSummaryItemColor(balance), whiteSpace: "nowrap" }}>
            {balance.toFixed(2)} {invoiceCurrency}
          </span>
        ),
    },

    {
      title: "Overdue",
      dataIndex: "overdue",
      sorter: (a, b) => a.overdue - b.overdue,
      render: (_: any, { overdue }: ISchoolBalance) =>
        overdue && (
          <span style={{ color: getSummaryItemColor(overdue), whiteSpace: "nowrap" }}>
            {overdue.toFixed(2)} {invoiceCurrency}
          </span>
        ),
    },
    {
      title: "Last invoice date",
      dataIndex: "lastInvoice",
      render: (_: any, { lastInvoice }: ISchoolBalance) => lastInvoice && <span>{lastInvoice}</span>,
    },
    {
      title: "Add Payment",
      dataIndex: "addPayment",
      render: (_: any, { schoolId }) => {
        return <AddSupraPayment schoolId={schoolId} revalidate={args.revalidate} />;
      },
    },
  ];
};
