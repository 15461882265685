import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faTable, faPlus, faCogs, faUserCircle, faSchool } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";
import { useAppSelector } from "store";
import { CreditCardFilled } from "@ant-design/icons";

const { Item: MenuItem, ItemGroup } = Menu;

type Props = {};

const DashboardNavigation2: FC<Props> = () => {
  const {
    mainPanelAdd,
    mainPanelPartnerAdd,
    mainPanelPartnersList,
    mainPanelSettings,
    mainPanelSchoolAdd,
    mainFinancialsPanel,
  } = useAppSelector(store => store.user.access);

  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          height: "100%",
          width: "100%",
          boxShadow: "3px 0px 6px 1px rgba(0,0,0,0.03)",
        }}
      >
        <Menu
          mode="vertical"
          style={{
            width: "100%",
            backgroundColor: "transparent",
            height: "100%",
          }}
          defaultSelectedKeys={["schools"]}
        >
          <StyledItemGroup color="#E50A5C">
            <Menu.Item key="schools" icon={<FontAwesomeIcon icon={faSchool} />} style={{ marginTop: "-4px" }}>
              <StyledLink to="schools">Schools</StyledLink>
            </Menu.Item>
            {mainPanelPartnersList && (
              <MenuItem key="partners" icon={<FontAwesomeIcon icon={faUserCircle} />}>
                <StyledLink to="/main/partners">Partners</StyledLink>
              </MenuItem>
            )}
            <Menu.Item key="carers" icon={<FontAwesomeIcon icon={faUsers} />}>
              <StyledLink to="/main/carers">Carers</StyledLink>
            </Menu.Item>
            <Menu.Item key="staff" icon={<FontAwesomeIcon icon={faUsers} />}>
              <Link to="/main/staff">Staff</Link>
            </Menu.Item>
            <Menu.Item key="kids" icon={<FontAwesomeIcon icon={faUsers} />}>
              <Link to="/main/kids">Kids</Link>
            </Menu.Item>
            <Menu.Item key="documents" icon={<FontAwesomeIcon icon={faTable} />}>
              <Link to="/main/documents">Documents</Link>
            </Menu.Item>
            {mainFinancialsPanel && (
              <Menu.Item key="invoice" icon={<CreditCardFilled />} color="#E50A5C">
                <Link to="/main/invoice">Financials</Link>
              </Menu.Item>
            )}
          </StyledItemGroup>
          {mainPanelAdd && (
            <StyledSubMenu
              key="add"
              icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />}
              title="Add"
              color="#00A1E1"
            >
              <StyledItemGroup key="add" title="Add" color="#00A1E1">
                {mainPanelSchoolAdd && (
                  <Menu.Item key="addSchool">
                    <Link to="/main/addSchool">School</Link>
                  </Menu.Item>
                )}

                {mainPanelPartnerAdd && (
                  <Menu.Item key="addPartner">
                    <Link to="/main/addPartner">Partner</Link>
                  </Menu.Item>
                )}
                <Menu.Item key="addStaff">
                  <StyledLink to="/main/addStaff">Staff</StyledLink>
                </Menu.Item>
              </StyledItemGroup>
            </StyledSubMenu>
          )}
          {mainPanelSettings && (
            <StyledSubMenu
              key="settings"
              icon={<FontAwesomeIcon icon={faCogs} style={{ marginRight: 10 }} />}
              title="Settings"
              color="#BDD000"
            >
              <StyledItemGroup key="settings" title="Settings" color="#BDD000">
                <Menu.Item key="countries">
                  <Link to="/main/countries">Countries</Link>
                </Menu.Item>
                <Menu.Item key="languages">
                  <Link to="/main/languages">Languages</Link>
                </Menu.Item>
                <Menu.Item key="religions">
                  <Link to="/main/religions">Religions</Link>
                </Menu.Item>
                <Menu.Item key="skills">
                  <Link to="/main/skills">Skills</Link>
                </Menu.Item>
                <Menu.Item key="domains">
                  <Link to="/main/domains">Domains</Link>
                </Menu.Item>
                <Menu.Item key="termUnits">
                  <Link to="/main/termUnits">Plan templates</Link>
                </Menu.Item>
              </StyledItemGroup>
            </StyledSubMenu>
          )}
        </Menu>
      </div>
    </>
  );
};

export default DashboardNavigation2;

const StyledItemGroup = styled(ItemGroup)<{ color: string }>`
  .ant-menu-item {
    border-left: ${({ color }) => `0px solid ${color}`};
    transition: all 0.15s ease-out;
    cursor: pointer;
    padding-left: 16px;
  }

  .ant-menu-item:active,
  .ant-menu-item:hover {
    color: ${({ color }) => `${color}DD`} !important;
    background-color: transparent;
    a {
      color: ${({ color }) => `${color}DD`} !important;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    border-left: ${({ color }) => `5px solid ${color}`};
    color: ${({ color }) => `${color}`} !important;

    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-selected:active,
    .ant-menu-submenu-selected:hover {
      color: ${({ color }) => `${color}`} !important;
      background-color: transparent;
      a {
        color: ${({ color }) => `${color}`} !important;
      }
    }

    border-left: ${({ color }) => `5px solid ${color}`};
    color: ${({ color }) => `${color}`} !important;
    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }

  .ant-menu-submenu-title {
    background-color: transparent !important;
    color: ${({ color }) => `${color}`} !important;

    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
`;

const StyledSubMenu = styled(SubMenu)<{ color: string }>`
  transition: border-left 0.15s ease-out;
  background-color: transparent !important;
  border-left: ${({ color }) => `0px solid ${color}`};

  a {
    color: ${({ color }) => `${color}`} !important;
  }

  .ant-menu-submenu-title:active,
  .ant-menu-submenu-title:hover {
    color: ${({ color }) => `${color}`} !important;
    a {
      color: ${({ color }) => `${color}`} !important;
    }
  }

  &.ant-menu-submenu-selected {
    border-left: ${({ color }) => `5px solid ${color}`} !important;
    color: ${({ color }) => `${color}`} !important;
  }
`;
