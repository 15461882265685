import { IModifiedRecord } from "../Summary/components/SummaryTable/SummaryTable";
import getSummaryItemColor from "./getSummaryItemColor";

interface IGetSummaryTableColumnsArgs {
  schoolCurrency: string;
}

export const getSummaryTableColumns = ({ schoolCurrency }: IGetSummaryTableColumnsArgs) => {
  return [
    {
      title: "Date",
      dataIndex: "date",
      editable: false,
    },
    {
      title: "Details",
      dataIndex: "details",
      editable: false,
    },
    {
      title: "Fees",
      dataIndex: "fees",
      editable: false,
      render: (_: any, record: IModifiedRecord) =>
        record.fees && (
          <span style={{ color: getSummaryItemColor(record?.fees), whiteSpace: "nowrap" }}>
            {record.fees} {schoolCurrency}
          </span>
        ),
    },
    {
      title: "Payments",
      dataIndex: "payments",
      editable: false,
      render: (_: any, record: IModifiedRecord) =>
        record.payments && (
          <span style={{ color: getSummaryItemColor(record?.payments), whiteSpace: "nowrap" }}>
            {record.payments} {schoolCurrency}
          </span>
        ),
    },
    {
      title: "Optional Reference",
      dataIndex: "optionalReference",
      editable: false,
    },
    {
      title: "Other",
      dataIndex: "other",
      editable: false,
      render: (_: any, record: IModifiedRecord) =>
        record.other && (
          <span style={{ color: getSummaryItemColor(record?.other), whiteSpace: "nowrap" }}>
            {record.other} {schoolCurrency}
          </span>
        ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      editable: false,
      render: (_: any, record: IModifiedRecord) =>
        record.balance && (
          <span style={{ color: getSummaryItemColor(record.balance), whiteSpace: "nowrap" }}>
            {record.balance.toFixed(2)} {schoolCurrency}
          </span>
        ),
    },
  ];
};
