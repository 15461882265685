import { Button, Card, Col, Row, Spin, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import CustomModal from "components/CustomModal";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import useSWR from "swr";
import { getChildPaymentsUrl, IChildPaymentsResponse, paymentPageSize, PaymentType } from "types";
import PaymentsList from "./PaymentsList";
import AddRefundModal from "./AddRefundModal";
import AddPaymentModal from "./AddPaymentModal";

const { Title } = Typography;

type Props = RouteComponentProps<{ id?: string; kidId?: string }, any, { id?: string; schoolId?: string }>;

export const Payments = ({ match, location }: Props) => {
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [addRefundModal, setAddRefundModal] = useState(false);
  const [paymentType, setPaymentType] = useState<PaymentType>("Refund");
  const [page, setPage] = useState(1);

  const schoolId = match.params.id ?? location.state.schoolId;
  const kidId = match.params.kidId ?? location.state.id;

  const queryParams = new URLSearchParams();
  queryParams.append("page", page.toString());
  queryParams.append("size", paymentPageSize.toString());

  const { data: childPayments, revalidate } = useSWR<IChildPaymentsResponse>(
    schoolId && kidId ? getChildPaymentsUrl(schoolId, kidId) + `?${queryParams.toString()}` : null
  );

  const isPending = !childPayments;

  const handleClosePaymentModal = () => {
    setAddPaymentModal(false);
  };

  const handleCloseRefundModal = () => {
    setAddRefundModal(false);
  };

  const handleOnFinishPayment = () => {
    setAddPaymentModal(false);
    revalidate();
  };

  const handleOnFinishRefund = () => {
    setAddRefundModal(false);
    revalidate();
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  const handlePaymentType = (type: PaymentType) => {
    setPaymentType(type);
  };

  if (isPending) {
    return <Spin spinning />;
  }

  return (
    <HeaderPayments>
      <Row>
        <Col span={24}>
          <Card>
            <div
              style={{
                backgroundColor: "#FFF",
                textAlign: "center",
                paddingBlock: 28,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div />
              <Title
                level={3}
                style={{ color: "#E50A5C", paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}
              >
                Payments
              </Title>
              <div>
                <Button
                  loading={false}
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                    fontSize: 14,
                  }}
                  onClick={async () => {
                    setAddPaymentModal(true);
                  }}
                >
                  Add payment
                </Button>
                <Button
                  loading={false}
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                    fontSize: 14,
                    marginLeft: 10,
                  }}
                  onClick={async () => setAddRefundModal(true)}
                >
                  Add refund or adjustment
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PaymentsList
            payments={childPayments.data}
            schoolId={schoolId}
            childId={kidId}
            revalidate={revalidate}
            totalSize={childPayments.meta.itemCount}
            changePage={changePage}
            page={page}
          />
        </Col>
      </Row>
      <CustomModal
        title="Add payment"
        isVisible={addPaymentModal}
        onClose={handleClosePaymentModal}
        width={800}
      >
        <AddPaymentModal schoolId={schoolId} kidId={kidId} onFinish={handleOnFinishPayment} />
      </CustomModal>
      <CustomModal
        title={paymentType === "Refund" ? "Add refund" : "Make an adjustment"}
        isVisible={addRefundModal}
        onClose={handleCloseRefundModal}
        width={800}
      >
        <AddRefundModal
          schoolId={schoolId}
          kidId={kidId}
          onFinish={handleOnFinishRefund}
          paymentType={paymentType}
          setSelectedPaymentType={handlePaymentType}
        />
      </CustomModal>
    </HeaderPayments>
  );
};

const HeaderPayments = styled.div`
  width: 100%;
  text-align: center;
`;
