import { InputRef, Table } from "antd";
import { Spacer } from "components";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { useRef } from "react";
import { IMultiSchoolFinancialRecord } from "../../FinancialMutliSchool";
import { getMultiSchoolFinancialsTableColumns } from "../../helpers";

interface Props {
  dataSource: IMultiSchoolFinancialRecord[];
  revalidate: () => Promise<boolean>;
}

export const MultiSchoolFinancialsTable = ({ dataSource, revalidate }: Props) => {
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (confirm: (param?: FilterConfirmProps) => void, clearFilters?: () => void) => {
    clearFilters && clearFilters();
    handleSearch(confirm);
  };

  return (
    <>
      <Spacer />
      <Table
        columns={getMultiSchoolFinancialsTableColumns({
          dataSource,
          searchInput,
          handleSearch,
          handleReset,
          revalidate,
        })}
        dataSource={dataSource}
      />
    </>
  );
};
