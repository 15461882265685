import { IFormValues } from "../types";

export const parseFormValues = (values: IFormValues) => {
  return Object.keys(values)
    .filter(
      key =>
        values[key as keyof IFormValues] !== undefined && key !== "rank" && key !== "type" && key !== "status"
    )
    .map(key => ({
      name: values[key as keyof IFormValues],
      lang: key,
    }));
};
