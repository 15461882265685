import { ColumnsType } from "antd/lib/table";
import { getUniqueClass } from "../../helpers";
import { IGetColumnSearch, getColumnSearch } from "../../helpers/getColumnSearch";
import { Link } from "react-router-dom";
import { ISingleSchoolFinancialRecord } from "../FinancialsSingleSchool";
import { AddPayment } from "screens/Invoice/components";
import kidImg from "../../../../img/placeholder/kid.png";
import getSummaryItemColor from "screens/Kid/Financials/helpers/getSummaryItemColor";

interface IGetInvoiceTableColumns extends Omit<IGetColumnSearch, "dataIndex"> {
  dataSource: ISingleSchoolFinancialRecord[];
  revalidate: () => Promise<boolean>;
  schoolCurrency: string;
  schoolId?: string;
}

export const getSingleSchoolTableFinancialColumns = (
  args: IGetInvoiceTableColumns
): ColumnsType<ISingleSchoolFinancialRecord> => {
  const { schoolId, revalidate } = args;

  return [
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearch({ ...args, dataIndex: "name" }),
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_: any, { childId, image, name }) => {
        return (
          <Link to={`/main/schools/${schoolId}/kids/${childId}/addKid`} style={{ color: "#000" }}>
            <div style={{ color: "#000", whiteSpace: "nowrap" }}>
              <img
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "5%",
                  marginRight: 10,
                  position: "relative",
                  top: 8,
                }}
                src={image ? image : kidImg}
                alt="avatar"
              />

              {name}
            </div>
          </Link>
        );
      },
    },
    {
      title: "Class",
      dataIndex: "class",
      filters: getUniqueClass(args.dataSource).map(classItem => ({ text: classItem, value: classItem })),
      filterSearch: true,
      onFilter: (value, record) => record.class.includes(value.toString()),
      sorter: (a, b) => a.class.localeCompare(b.class),
    },
    {
      title: "Last payment",
      dataIndex: "lastPayment",
      sorter: (a, b) => a.lastPayment - b.lastPayment,
      render: (_: any, { lastPayment }) =>
        lastPayment && (
          <span style={{ color: getSummaryItemColor(lastPayment), whiteSpace: "nowrap" }}>
            {lastPayment.toFixed(2)} {args.schoolCurrency}
          </span>
        ),
    },
    {
      title: "Overall Balance",
      dataIndex: "totalBalance",
      sorter: (a, b) => a.totalBalance - b.totalBalance,
      render: (_: any, { totalBalance }) =>
        totalBalance && (
          <span style={{ color: getSummaryItemColor(totalBalance), whiteSpace: "nowrap" }}>
            {totalBalance.toFixed(2)} {args.schoolCurrency}
          </span>
        ),
    },
    {
      title: "Monthly Balance",
      dataIndex: "monthlyBalance",
      sorter: (a, b) => a.monthlyBalance - b.monthlyBalance,
      render: (_: any, { monthlyBalance }) =>
        monthlyBalance && (
          <span style={{ color: getSummaryItemColor(monthlyBalance), whiteSpace: "nowrap" }}>
            {monthlyBalance.toFixed(2)} {args.schoolCurrency}
          </span>
        ),
    },
    {
      title: "Overdue (amount)",
      dataIndex: "overDueAmount",
      sorter: (a, b) => a.overDueAmount - b.overDueAmount,
      render: (_: any, { overDueAmount }) =>
        overDueAmount && (
          <span style={{ color: getSummaryItemColor(overDueAmount), whiteSpace: "nowrap" }}>
            {overDueAmount.toFixed(2)} {args.schoolCurrency}
          </span>
        ),
    },
    {
      title: "Overdue (days)",
      dataIndex: "overDueDays",
      sorter: (a, b) => a.overDueDays - b.overDueDays,
    },
    {
      title: "Add Payment",
      dataIndex: "addPayment",
      render: (_: any, { childId }) => {
        return <AddPayment kidId={childId.toString()} schoolId={schoolId} revalidate={revalidate} />;
      },
    },
  ];
};
