import { Typography, Switch } from "antd";
import { Spacer } from "components";
import { ViewType } from "enums";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import { NoDataInfo } from "../components";
import { TermFeesTable } from "./components";
import { parseTermFees } from "./helpers";

export interface ITermFeesData {
  amount: number;
  term: string;
  id: number;
  time: string;
}

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

export const TermFees = (props: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { Title } = Typography;
  const schoolId = props.match.params.id;

  const { data: termFeesData, revalidate } = useSWR<ITermFeesData[]>(
    `school/${schoolId}/financials/settings/term-fees`
  );

  return (
    <>
      <RowRightContainer>
        <Switch
          checkedChildren="Edit mode"
          unCheckedChildren="Edit mode"
          defaultChecked
          onChange={val => setIsEditMode(val)}
          checked={isEditMode}
        />
      </RowRightContainer>
      <Spacer size={20} />
      <Title
        level={3}
        style={{
          color: "#E50A5C",
          paddingBottom: "0px",
          marginBottom: "0px",
          backgroundColor: "#FFF",
          textAlign: "center",
          paddingBlock: 28,
        }}
      >
        Term Fees
      </Title>
      <Spacer size={40} />

      {termFeesData && termFeesData.length > 0 ? (
        parseTermFees(termFeesData).map(({ term, times }) => (
          <TermFeesTable
            termName={term}
            dataSource={times}
            key={term}
            isEditMode={isEditMode}
            revalidate={revalidate}
            schoolId={schoolId}
          />
        ))
      ) : (
        <NoDataInfo title="You have not added any terms or term times for this school" />
      )}
    </>
  );
};

const RowRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-content: flex-end;
`;

export default TermFees;
