import { ColumnsType } from "antd/lib/table";
import { getColumnSearch, getUniqueClass, getUniqueSchool } from "../../helpers";
import { IGetColumnSearch } from "../../helpers/getColumnSearch";
import { Link } from "react-router-dom";
import { IMultiSchoolFinancialRecord } from "../FinancialMutliSchool";
import { AddPayment } from "screens/Invoice/components";
import getSummaryItemColor from "screens/Kid/Financials/helpers/getSummaryItemColor";
import kidImg from "../../../../img/placeholder/kid.png";

interface IGetInvoiceTableColumns extends Omit<IGetColumnSearch, "dataIndex"> {
  dataSource: IMultiSchoolFinancialRecord[];
  revalidate: () => Promise<boolean>;
}

export const getMultiSchoolFinancialsTableColumns = (
  args: IGetInvoiceTableColumns
): ColumnsType<IMultiSchoolFinancialRecord> => {
  const { revalidate } = args;

  return [
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearch({ ...args, dataIndex: "name" }),
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_: any, { schoolId, childId, image, name }) => {
        return (
          <Link
            to={`/main/schools/${schoolId}/kids/${childId}/addKid`}
            style={{ color: "#000", whiteSpace: "nowrap" }}
          >
            <div>
              <img
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "5%",
                  marginRight: 10,
                  position: "relative",
                  top: 8,
                }}
                src={image ? image : kidImg}
                alt="avatar"
              />
              {name}
            </div>
          </Link>
        );
      },
    },
    {
      title: "School",
      dataIndex: "schoolName",
      filters: getUniqueSchool(args.dataSource).map(schoolName => ({ text: schoolName, value: schoolName })),
      filterSearch: true,
      onFilter: (value, record) => record.schoolName.includes(value.toString()),
      sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
    },
    {
      title: "Class",
      dataIndex: "class",
      filters: getUniqueClass(args.dataSource).map(classItem => ({ text: classItem, value: classItem })),
      filterSearch: true,
      onFilter: (value, record) => record.class.includes(value.toString()),
      sorter: (a, b) => a.class.localeCompare(b.class),
    },
    {
      title: "Last payment",
      dataIndex: "lastPayment",
      sorter: (a, b) => a.lastPayment - b.lastPayment,
      render: (_: any, { lastPayment, currency }) =>
        lastPayment && (
          <span style={{ color: getSummaryItemColor(lastPayment), whiteSpace: "nowrap" }}>
            {lastPayment.toFixed(2)} {currency}
          </span>
        ),
    },
    {
      title: "Total Balance",
      dataIndex: "totalBalance",
      sorter: (a, b) => a.totalBalance - b.totalBalance,
      render: (_: any, { totalBalance, currency }) =>
        totalBalance && (
          <span style={{ color: getSummaryItemColor(totalBalance), whiteSpace: "nowrap" }}>
            {totalBalance.toFixed(2)} {currency}
          </span>
        ),
    },
    {
      title: "Monthly Balance",
      dataIndex: "monthlyBalance",
      sorter: (a, b) => a.monthlyBalance - b.monthlyBalance,
      render: (_: any, { monthlyBalance, currency }) =>
        monthlyBalance && (
          <span style={{ color: getSummaryItemColor(monthlyBalance), whiteSpace: "nowrap" }}>
            {monthlyBalance.toFixed(2)} {currency}
          </span>
        ),
    },
    {
      title: "Overdue (amount)",
      dataIndex: "overDueAmount",
      sorter: (a, b) => a.overDueAmount - b.overDueAmount,
      render: (_: any, { overDueAmount, currency }) =>
        overDueAmount && (
          <span style={{ color: getSummaryItemColor(overDueAmount), whiteSpace: "nowrap" }}>
            {overDueAmount.toFixed(2)} {currency}
          </span>
        ),
    },
    {
      title: "Overdue (days)",
      dataIndex: "overDueDays",
      sorter: (a, b) => a.overDueDays - b.overDueDays,
    },
    {
      title: "Add Payment",
      dataIndex: "addPayment",
      render: (_: any, { childId, schoolId }) => {
        return (
          <AddPayment kidId={childId.toString()} schoolId={schoolId.toString()} revalidate={revalidate} />
        );
      },
    },
  ];
};
