import { Form, Select, Upload, Button } from "antd";
import { ErrorMessage } from "enums";
import { normFile } from "helpers";
import { PlusOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";

interface Props {
  documentsTypes: {
    label: string;
    value: number;
  }[];
}

export const SelectDocument = ({ documentsTypes }: Props) => {
  return (
    <Form.List name="documents">
      {(fields, { add, remove }) => (
        <>
          {fields.map(field => (
            <div
              key={field.key}
              style={{
                display: "flex",
                alignItems: "flex-start",
                alignContent: "center",
                flexDirection: "row",
                position: "relative",
                marginBottom: "35px",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                shouldUpdate
                {...field}
                name={[field.name, "documentType"]}
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                key={field.key}
              >
                <Select options={documentsTypes} style={{ width: 360 }} />
              </Form.Item>
              <Form.Item noStyle shouldUpdate={true}>
                {({ getFieldValue, setFieldsValue }) => {
                  const documents = getFieldValue("documents");

                  return (
                    <Form.Item
                      {...field}
                      name={[field.name, "originFileObj"]}
                      valuePropName="fileList"
                      style={{
                        marginBottom: "0px",
                        marginRight: "20px",
                      }}
                      getValueFromEvent={normFile}
                      rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                    >
                      <Upload
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={info => {
                          // Normalize the file information using the normFile function
                          const newFilesList = normFile(info);

                          // Map over the documents array and create a new array of new files
                          const newFiles = documents.map((item: any, index: number) => {
                            // If the field key matches the current index, merge the item with the new file information
                            if (field.key === index) {
                              return {
                                ...item,
                                ...newFilesList[0],
                              };
                            } else {
                              // Otherwise, return a copy of the original item
                              return { ...item };
                            }
                          });

                          // Update the documents field in the form with the new array of new files
                          setFieldsValue({
                            documents: newFiles,
                          });
                        }}
                      >
                        <Button icon={<UploadOutlined />}>Select document</Button>
                      </Upload>
                      <Text
                        ellipsis
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "0%",
                          width: "150px",
                        }}
                      >
                        {documents[field.name]?.name}
                      </Text>
                      <DeleteOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ color: "#E50A5C", marginLeft: "20px" }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          ))}
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
              Add document
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
