import { Button, Col, Popconfirm, Row, Table } from "antd";

import useSWR from "swr";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router-dom";
import { useState } from "react";

import { ViewType } from "enums";

import { Kid } from "screens/Kids/Kids";

import { FeeConfiguration, CampFeeConfiguration, FeeHeader, AdditionalFeeConfiguration } from "./components";

import { useAdditionalFees, useCampsFees, useFinancialsFees } from "./hooks";

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{}, {}, { schoolId: number; id: number }>;

const activeButtonStyle = {
  backgroundColor: "#BDD000",
  border: "1px solid #BDD000",
  color: "white",
  outline: "none",
};

enum ChildFeeTab {
  Term = "TERM",
  Camp = "CAMP",
  Additional = "ADDITIONAL",
}

export const Fees = (props: Props) => {
  const schoolId = props.location.state.schoolId;
  const selectedKidId = props.location.state.id;
  const [childFeeTab, setChildFeeTab] = useState(ChildFeeTab.Term);

  const pickedTermTab = childFeeTab === ChildFeeTab.Term;
  const pickedCampTab = childFeeTab === ChildFeeTab.Camp;
  const pickedAdditionalTab = childFeeTab === ChildFeeTab.Additional;

  const {
    mappedCampsData,
    pickedCamp,
    revalidateCamps,
    setPickedCamp,
    addCampFeeToStatement,
    campConfigLookups,
    campPreview,
  } = useCampsFees({ pickedCampTab, selectedKidId, schoolId });

  const {
    isValidating,
    statementPreview,
    addFeeToStatement,
    financeConfig,
    financeConfigLookups,
    mappedTermsData,
    pickedTerm,
    setPickedTerm,
    revalidate,
    data,
  } = useFinancialsFees({ selectedKidId, schoolId, pickedTermTab });

  const {
    additionalConfigLookups,
    additionalStatementPreview,
    revalidateAdditionalConfig,
    addAdditionalFeeToStatement,
  } = useAdditionalFees({
    childId: selectedKidId,
    schoolId,
  });

  const { data: kidData } = useSWR<Kid>(`child/config/${selectedKidId}`);

  if (isValidating && !data) {
    return <div>Loading...</div>;
  }

  const pickChildFeeTermTab = () => setChildFeeTab(ChildFeeTab.Term);
  const pickChildFeeCampTab = () => setChildFeeTab(ChildFeeTab.Camp);
  const pickChildFeeAdditionalTab = () => setChildFeeTab(ChildFeeTab.Additional);

  const { term = null, items = [] } = statementPreview || {};

  const { items: campItems = [], camps } = campPreview || {};

  const shouldDisableSave = term === null || term === undefined;
  const shouldDisableCampSave = camps === null || camps === undefined;

  const shouldDisableGeneratingFee = items.length === 0 || term === null || term === undefined;
  const shouldDisableGeneratingCampFee =
    campItems.length === 0 || pickedCamp === null || pickedCamp === undefined;
  const shouldDisableGeneratingAdditionalFee = additionalStatementPreview?.items?.length === 0;

  const shouldDisableFeeStatement = pickedTermTab
    ? shouldDisableGeneratingFee
    : pickedCampTab
    ? shouldDisableGeneratingCampFee
    : shouldDisableGeneratingAdditionalFee;

  const disabledStyles = shouldDisableFeeStatement
    ? {
        backgroundColor: "#dddddd",
        borderColor: "#dddddd",
        color: "black",
      }
    : {};

  const getGenerateStatementFunction = () => {
    if (pickedTermTab) {
      return addFeeToStatement();
    }
    if (pickedCampTab) {
      return addCampFeeToStatement();
    }
    if (pickedAdditionalTab) {
      return addAdditionalFeeToStatement();
    }
  };

  return (
    <div>
      <Row>
        <Col span={14} style={{ marginTop: -60 }}>
          <Button style={pickedTermTab ? activeButtonStyle : {}} onClick={pickChildFeeTermTab}>
            Terms
          </Button>
          <Button
            style={pickedCampTab ? { ...activeButtonStyle, marginLeft: 8 } : { marginLeft: 8 }}
            onClick={pickChildFeeCampTab}
          >
            Camps
          </Button>
          <Button
            style={pickedAdditionalTab ? { ...activeButtonStyle, marginLeft: 8 } : { marginLeft: 8 }}
            onClick={pickChildFeeAdditionalTab}
          >
            Additional
          </Button>
        </Col>
        <Col span={pickedTermTab ? 13 : 17} style={{ display: "flex", flexDirection: "column" }}>
          <FeeHeader
            title="Term fees configuration"
            isVisible={pickedTermTab}
            kidData={kidData}
            selectData={{
              value: pickedTerm,
              onChange: setPickedTerm,
              options: mappedTermsData,
            }}
            additionalInformation={term && !term.termHasAssignedFee ? "Term has no fees assigned" : undefined}
          />
          <FeeHeader
            title="Camp fees configuration"
            isVisible={pickedCampTab}
            kidData={kidData}
            selectData={{
              value: pickedCamp,
              onChange: setPickedCamp,
              options: mappedCampsData,
            }}
            additionalInformation={
              mappedCampsData && mappedCampsData.length === 0 ? "No camp selected in child profile" : ""
            }
          />
          <FeeHeader
            title="Additional fees configuration"
            isVisible={pickedAdditionalTab}
            kidData={kidData}
            additionalInformation={
              additionalConfigLookups?.steamFees?.length === 0 ? "Check steam fee configuration" : undefined
            }
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popconfirm
              onConfirm={getGenerateStatementFunction}
              title="Are you sure you want to add fees to the Summary? Once you do this it will be shared immediately with the carer on the Parent App Fee Statement."
              okText="Yes"
              cancelText="No"
              disabled={shouldDisableFeeStatement}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "#BDD000",
                  borderColor: "#BDD000",
                  color: "white",
                  marginTop: 16,
                  marginBottom: 16,
                  ...disabledStyles,
                }}
                disabled={shouldDisableFeeStatement}
              >
                Add fees to summary
              </Button>
            </Popconfirm>
          </div>
          <Table
            dataSource={pickedTermTab ? items : pickedCampTab ? campItems : additionalStatementPreview?.items}
            pagination={{ pageSize: 20 }}
          >
            <Table.Column
              title="Date"
              dataIndex="createdAt"
              key="date"
              width="20%"
              render={record => dayjs(record).format("DD-MM-YYYY")}
            />
            <Table.Column title="Name" dataIndex="itemName" key="name" width="60%" />
            <Table.Column title="Amount" dataIndex="itemAmount" key="amount" width="20%" />
          </Table>
        </Col>
        {pickedTermTab && (
          <Col span={10} offset={1} style={{ backgroundColor: "#EAE9E9", padding: "10px", borderRadius: 8 }}>
            <FeeConfiguration
              financeConfig={financeConfig}
              configuration={financeConfigLookups}
              revalidate={revalidate}
              shouldDisableSave={shouldDisableSave}
              selectedKidId={selectedKidId}
              schoolId={schoolId}
              pickedTerm={pickedTerm}
            />
          </Col>
        )}
        {pickedCampTab && (
          <Col span={6} offset={1} style={{ backgroundColor: "#EAE9E9", padding: "10px", borderRadius: 8 }}>
            <CampFeeConfiguration
              campFeeConfig={campConfigLookups}
              shouldDisableSave={shouldDisableCampSave}
              selectedKidId={selectedKidId}
              schoolId={schoolId}
              revalidate={revalidateCamps}
            />
          </Col>
        )}
        {pickedAdditionalTab && additionalConfigLookups && (
          <Col span={6} offset={1} style={{ backgroundColor: "#EAE9E9", padding: "10px", borderRadius: 8 }}>
            <AdditionalFeeConfiguration
              additionalConfigLookups={additionalConfigLookups}
              revalidate={revalidateAdditionalConfig}
              schoolId={schoolId}
              selectedKidId={selectedKidId}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
