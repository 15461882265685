import { Button, Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import {
  getChildPaymentsUrl,
  IChildPaymentBody,
  PaymentCategoryTypeDto,
  paymentMethod,
  PaymentType,
} from "types";
import styled from "styled-components";
import { useState } from "react";

interface IProps {
  schoolId?: string;
  kidId?: string;
  onFinish: () => void;
  setSelectedPaymentType: (sectionType: PaymentType) => void;
  paymentType: PaymentType;
}

const AddRefundModal = ({ schoolId, kidId, onFinish, setSelectedPaymentType, paymentType }: IProps) => {
  const [form] = useForm();
  const [isSending, setIsSending] = useState(false);
  const firstDayOfTheMonth = dayjs().startOf("month");
  const endDayOfTheMonth = dayjs().endOf("month");
  const getPaymentType = (amountSymbol: number) => {
    if (paymentType === "Adjustment" && Number(amountSymbol) === 1) {
      return PaymentCategoryTypeDto.ADJUSTMENT_INCREASE;
    }

    return PaymentCategoryTypeDto.ADJUSTMENT_REFUND;
  };

  const handleSubmit = async (values: IChildPaymentBody & { amountSymbol?: number }) => {
    setIsSending(true);
    const { amount, paymentDate, paymentMethod, reference, amountSymbol } = values;

    const requestBody: IChildPaymentBody = {
      amount: Number(amount),
      paymentDate: dayjs(paymentDate).toDate(),
      paymentMethod,
      reference,
      type: amountSymbol ? getPaymentType(amountSymbol) : PaymentCategoryTypeDto.REFUND,
    };

    try {
      if (!schoolId || !kidId) return;

      await axiosAuth.post(getChildPaymentsUrl(schoolId, kidId), requestBody);
      message.success("Payment has been saved");
      onFinish();
    } catch (err: any) {
      message.error("Something went wrong");
    } finally {
      form.resetFields();
      setIsSending(false);
    }
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <Button
              style={
                paymentType === "Refund"
                  ? { marginLeft: 10, backgroundColor: "#1890FF", color: "white" }
                  : { marginLeft: 10 }
              }
              onClick={() => setSelectedPaymentType("Refund")}
            >
              Refund
            </Button>
            <Button
              style={
                paymentType === "Adjustment"
                  ? { marginLeft: 10, backgroundColor: "#1890FF", color: "white" }
                  : { marginLeft: 10 }
              }
              onClick={() => setSelectedPaymentType("Adjustment")}
            >
              Adjustment
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="paymentDate"
              label="Date: "
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <DatePicker
                onChange={e => {}}
                style={{ width: "100%" }}
                disabledDate={date =>
                  dayjs(date.format()).isBefore(firstDayOfTheMonth) ||
                  dayjs(date.format()).isAfter(endDayOfTheMonth)
                }
              />
            </Form.Item>
          </Col>
          {paymentType === "Refund" ? (
            <Col span={12}>
              <Form.Item
                name="paymentMethod"
                label="Method: "
                labelCol={{ span: 10 }}
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Select
                  showSearch
                  options={paymentMethod}
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item
                name="reference"
                label="Reference: "
                labelCol={{ span: 10 }}
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Input placeholder="example" type="text" />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row>
          <Col span={12} style={{ display: "flex" }}>
            <div>
              <Row>
                {paymentType === "Adjustment" && (
                  <Col>
                    <Form.Item
                      name="amountSymbol"
                      label="Amount"
                      rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                      style={{ marginRight: 5 }}
                    >
                      <Select
                        options={[
                          { label: "+", value: "1" },
                          { label: "-", value: "2" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={paymentType === "Adjustment" ? 11 : 24}>
                  <Form.Item
                    name="amount"
                    label={paymentType === "Adjustment" ? "" : "Amount"}
                    rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                  >
                    <Input placeholder="example" type="number" min={1} step="0.01" />
                  </Form.Item>
                </Col>
              </Row>
              {paymentType === "Adjustment" && (
                <AmountSignDescription>
                  If '+' is selected, the parent will have LESS to pay as you are making an adjustment in
                  their favour, while if '-' is selected, the parent will have MORE to pay.
                </AmountSignDescription>
              )}
            </div>
          </Col>
          {paymentType === "Refund" && (
            <Col span={12}>
              <Form.Item
                name="reference"
                label="Reference: "
                labelCol={{ span: 10 }}
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Input placeholder="example" type="text" />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="text"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                  disabled={isSending}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddRefundModal;

const AmountSignDescription = styled.span`
  font-size: 14px;
`;
