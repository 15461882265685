import { Typography } from "antd";
import styled from "styled-components";
import { ExportToCsv, SummaryFilter, SummaryInfo } from "../components";
import { MultiSchoolFinancialsTable } from "./components";
import { NoDataInfo } from "screens/Financials/components";
import useSWR from "swr";
import { SummaryFinancials, currentDate, dateFormat, firstDayOfMonth } from "../const";
import moment from "moment";
import { useState } from "react";

export interface IInvoiceSummary {
  revenue: number;
  overDueAmount: number;
  refunds: number;
}
export interface IMultiSchoolFinancialRecord {
  schoolId: number;
  childId: number;
  monthlyBalance: number;
  totalBalance: number;
  name: string;
  overDueAmount: number;
  lastPayment: any;
  overDueDays: number;
  image: any;
  class: string;
  schoolName: string;
  currency: string;
}

export const FinancialMultiSchool = () => {
  const { Title } = Typography;

  const [filterOption, setFilterOption] = useState<{ startDate: string; endDate: string }>({
    startDate: moment(firstDayOfMonth).format(dateFormat).toString(),
    endDate: moment(currentDate).format(dateFormat).toString(),
  });

  const queryParams = new URLSearchParams();
  queryParams.append("startDate", filterOption.startDate);
  queryParams.append("endDate", filterOption.endDate);

  const { data: invoiceSummary, revalidate: revalidateSummary } = useSWR<IInvoiceSummary>(
    `financials/revenues/partner?${queryParams.toString()}`
  );
  const { data: invoiceData, revalidate } = useSWR<IMultiSchoolFinancialRecord[]>(
    `financials/revenues/partner/list`
  );

  const { revenue, overDueAmount, refunds } = invoiceSummary ?? {};

  const summaryData = [
    { name: SummaryFinancials.REVENUE, price: revenue },
    { name: SummaryFinancials.OVERDUE, price: overDueAmount },
    { name: SummaryFinancials.REFUNDS, price: refunds },
  ];

  if (!invoiceData) {
    return (
      <NoDataContainer>
        <NoDataInfo title="No financial history data for this school" />
      </NoDataContainer>
    );
  }

  const handleChangeRangeDate = (dateArray: [string, string]) => {
    setFilterOption({ startDate: dateArray[0], endDate: dateArray[1] });
  };

  const revalidateBoth = async () => {
    return (await revalidate()) && (await revalidateSummary());
  };

  return (
    <>
      <InvoiceContainer>
        <Title level={3} style={{ textAlign: "left", color: "#E50A5C" }}>
          Summary
        </Title>
        <SummaryContainer>
          <AmountContainer>
            {summaryData.map(({ name, price }) => (
              <SummaryInfo title={name} price={price ? price : 0} key={name} />
            ))}
            <SummaryFilter handleChangeRangeDate={handleChangeRangeDate} />
          </AmountContainer>
          <ExportToCsv dataSource={invoiceData} fileTitle={"Invoice_summary.csv"} />
        </SummaryContainer>
      </InvoiceContainer>
      <MultiSchoolFinancialsTable dataSource={invoiceData} revalidate={revalidateBoth} />
    </>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: end;
`;

const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px;
  border-radius: 10px;
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
