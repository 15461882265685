import { Spacer } from "components";
import useSWR from "swr";
import dayjs, { Dayjs } from "dayjs";
import { ViewType } from "enums";
import { RouteComponentProps } from "react-router-dom";
import { SummaryTable } from "./components";
import { getChildFinancialSummaryUrl } from "../helpers";
import { Typography } from "antd";
import getSummaryItemColor from "../helpers/getSummaryItemColor";
import styled from "styled-components";

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string; kidId?: string }>;

export type ICategory = "FEE" | "DISCOUNT";

interface ISummaryData {
  monthlyBalance: number;
  overDueAmount: number;
  overDueDate: string;
  totalBalance: number;
  items: IRecord[];
}

export interface IRecord {
  id: number;
  date: Dayjs;
  details: string;
  fees: number;
  payments: number;
  optionalReference: string;
  other: number;
  balance: number;
  category: ICategory;
}

export const Summary = (props: Props) => {
  const { Title } = Typography;
  const { data: schoolData } = useSWR(`school/${props.match.params.id}`);

  const financialSummaryUrl = getChildFinancialSummaryUrl(props.match.params.id, props.match.params.kidId);

  const { data: summaryData } = useSWR<ISummaryData>(financialSummaryUrl);

  const schoolCurrency = schoolData?.currency;

  const mappedData = summaryData?.items
    ?.map(item => ({ ...item, date: dayjs(item.date).format("DD/MM/YYYY") }))
    .reverse();

  const totalBalance = summaryData?.totalBalance;
  const monthlyBalance = summaryData?.monthlyBalance;

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF",
          textAlign: "center",
          paddingBlock: 28,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div />
        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
            textAlign: "center",
          }}
        >
          Summary
        </Title>
        <BalanceContainer>
          <div style={{ marginRight: 20 }}>
            Total balance
            {totalBalance ? (
              <Title level={5} style={{ color: getSummaryItemColor(totalBalance), whiteSpace: "nowrap" }}>
                {totalBalance.toFixed(2)} {schoolCurrency}
              </Title>
            ) : (
              <Title level={5} style={{ color: "green" }}>
                0 {schoolCurrency}
              </Title>
            )}
          </div>
          <div style={{ marginRight: 20 }}>
            Monthly balance
            {monthlyBalance ? (
              <Title level={5} style={{ color: getSummaryItemColor(monthlyBalance), whiteSpace: "nowrap" }}>
                {monthlyBalance.toFixed(2)} {schoolCurrency}
              </Title>
            ) : (
              <Title level={5} style={{ color: "green" }}>
                0 {schoolCurrency}
              </Title>
            )}
          </div>
        </BalanceContainer>
      </div>
      <Spacer size={20} />
      <SummaryTable schoolCurrency={schoolCurrency} dataSource={mappedData} />
    </>
  );
};

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
