export const baseDocumentTypes = {
  0: "Other",
};

export const staffRequiredDocumentTypes = {
  230: "Signed Curriculum Confidentiality agreement",
  235: "Signed Staff Handbook Acknowledgment form",
  240: "Signed Contract",
};

export const staffRequiredEducationDocumentTypes = {
  200: "Diploma Masters Educationo",
  205: "Diploma Masters Other",
  210: "Bachelors Masters Other",
  215: "Bachelors Masters Other",
  220: "Post Graduate Certificate Education",
  225: "Post Graduate Certificate Other",
  245: "Highest degree",
};

export const staffRequiredEducationDocumentTypesNew = {
  250: "Diploma or Certificate",
  255: "Graduate Degree Education",
  260: "Graduate Degree Non-Education",
  265: "Bachelor Degree Education",
  270: "Bachelor Degree Non-Education",
  275: "Diploma or Certificate",
  280: "Hamk Certificate",
};

export const staffDocumentTypes = {
  121: "Passport",
  ...staffRequiredEducationDocumentTypes,
  ...staffRequiredEducationDocumentTypesNew,
  ...staffRequiredDocumentTypes,
};

export const staffDocumentTypesNew = {
  121: "Passport",
  ...staffRequiredEducationDocumentTypesNew,
  ...staffRequiredDocumentTypes,
};

export const kidDocumentTypes = {
  10: "Passport",
  100: "Birth Certificate",
  110: "Vaccination and Immunisation Record",
  120: "Signed in Application From / Contact",
};
export const schoolDocumentsTypes = {
  310: "Public Liability Insurance Certificate",
  311: "Building & Contents Insurance Certificate",
  312: "Local Authority Approval",
  313: "Local Authority Education License",
  314: "Food Hygiene License",
  315: "Transportation License",
};

export const partnerDocumentTypes = {
  410: "Franchise Agreement",
  411: "Certificate of Incorporation",
  412: "Passport",
};

export const staffDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...staffDocumentTypes,
  ...staffDocumentTypesNew,
  ...staffRequiredDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const staffDocumentTypesListSelect = Object.entries({
  ...baseDocumentTypes,
  ...staffDocumentTypesNew,
  ...staffRequiredDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const staffRequiredDocumentTypesList = Object.entries({
  ...staffRequiredDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const staffRequiredEducationDocumentTypesList = Object.entries({
  ...staffRequiredEducationDocumentTypes,
  ...staffRequiredEducationDocumentTypesNew,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const kidDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...kidDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const schoolDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...schoolDocumentsTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const partnerDocumentTypesList = Object.entries({
  ...baseDocumentTypes,
  ...partnerDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const allDocumentTypes = {
  ...baseDocumentTypes,
  ...schoolDocumentsTypes,
  ...kidDocumentTypes,
  ...staffDocumentTypes,
  ...partnerDocumentTypes,
};

export const allDocumentTypesFilter = Object.entries({
  ...allDocumentTypes,
}).map(([key, val]) => ({
  text: val,
  value: parseInt(key),
}));

export const allDocumentTypesList = Object.entries({
  ...allDocumentTypes,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));
