import { FC, useEffect, useState } from "react";
import { Modal, Button, Typography, Form, Input, Select, Row, Col, message, InputNumber } from "antd";
import { groupsList } from "const";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { languages } from "../const";
import { IFormValues } from "../types";
import { parseFormValues } from "../helpers";
import { DevelopmentCategoryType } from "./DevelopmentCategory";

const { Title, Text } = Typography;

export type InitialData = {};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: DevelopmentCategoryType | null;
  revalidate: () => Promise<boolean>;
};

const DevelopmentCategoryModal: FC<Props> = ({ isVisible, onClose, initialData, revalidate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const title = initialData ? "Update skill category" : "Create skill category";

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  });

  const onFinish = async (values: IFormValues) => {
    try {
      setIsLoading(true);

      const developmentCategoryLang = parseFormValues(values);

      const { status, type, rank } = values;

      const developmentCategoryBody = {
        type,
        rank,
        status,
        developmentCategoryLang,
      };

      const { id } = initialData ?? {};

      if (initialData) {
        await axiosAuth.put(`development-category/${id}`, developmentCategoryBody);
      } else {
        await axiosAuth.post("development-category", developmentCategoryBody);
      }
      await revalidate();
      message.success(`${title} successfully`);
      form.resetFields();
      handleOnClose();
    } catch (error) {
      message.error(`Error occured while ${title}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClose = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          {title}
        </Title>
      }
      open={isVisible}
      onCancel={handleOnClose}
      footer={false}
    >
      <Form name="basic" initialValues={initialData ?? {}} onFinish={onFinish} form={form}>
        <Text>Name</Text>
        <Row gutter={[24, 0]}>
          {languages.map(({ label, value, required }) => (
            <Col span={24} key={value}>
              <Form.Item
                label={<label style={{ color: "gray", fontSize: "1.4rem" }}>[{label}]</label>}
                name={value}
                rules={required ? [{ required: true, message: ErrorMessage.REQUIRED }] : []}
                labelCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
          ))}
          <Col span={24}>
            <Form.Item
              label={<label style={{ fontSize: "1.4rem" }}>Rank</label>}
              name="rank"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <InputNumber type="number" controls={false} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              style={{ flex: 1 }}
              labelCol={{ span: 24 }}
            >
              <Select options={groupsList} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" name="status" style={{ flex: 1 }} labelCol={{ span: 24 }}>
              <Select>
                <Select.Option value={10}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DevelopmentCategoryModal;
