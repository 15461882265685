import { InputRef, Table } from "antd";
import { Spacer } from "components";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { Dispatch, SetStateAction, useRef } from "react";
import { ISchoolBalance } from "../../FinancialsSupra";
import { getSupraInvoiceTableColumns } from "../../helpers";

interface Props {
  dataSource: ISchoolBalance[];
  revalidate: () => Promise<boolean>;
  setSelectedSchoolId: Dispatch<SetStateAction<number[] | undefined>>;
}

export const FinancialsSupraTable = ({ dataSource, revalidate, setSelectedSchoolId }: Props) => {
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (confirm: (param?: FilterConfirmProps) => void, clearFilters?: () => void) => {
    clearFilters && clearFilters();
    handleSearch(confirm);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ISchoolBalance[]) => {
      const selectedSchoolId = selectedRows.map(({ schoolId }) => schoolId);
      setSelectedSchoolId(selectedSchoolId);
    },
    getCheckboxProps: (record: any) => {
      const isSchoolEmail = dataSource.find(({ email }) => email === record.email && email);

      return {
        disabled: isSchoolEmail ? false : true,
      };
    },
  };

  return (
    <>
      <Spacer />
      <span style={{ fontSize: 14 }}>
        You can't send an invoice to a school that doesn't have an email address set up
      </span>
      <Spacer />
      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        columns={getSupraInvoiceTableColumns({
          dataSource,
          searchInput,
          handleSearch,
          handleReset,
          revalidate,
        })}
        dataSource={dataSource}
      />
    </>
  );
};
