import { Button, message } from "antd";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";

interface Props {
  fileTitle: string;
  dataSource: string | Data | (() => string | Data);
}

export const ExportToCsv = ({ fileTitle, dataSource }: Props) => {
  return (
    <Button>
      <CSVLink
        filename={fileTitle}
        data={dataSource}
        className="btn btn-primary"
        onClick={() => {
          message.success("The file is downloading");
        }}
      >
        Export to CSV
      </CSVLink>
    </Button>
  );
};
