import { Button, Form, InputNumber, Row, Table, message } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import useSWR from "swr";
import {
  InitialSteamFees,
  PickedSteamFees,
  getInitialSteamFees,
  getSteamFeesList,
  getUpdateSteamFeeUrl,
} from "types";
import { axiosAuth } from "helpers";
import { isErrorInfo } from "screens/Financials/Utilities/helpers";

enum SteamFees {
  ArchaeologicalAdventure = "archaeologicalAdventure",
  EcoChampions = "ecoChampions",
  FutureFilmmakers = "futureFilmmakers",
  JourneyToAtlantis = "journeyToAtlantis",
  LifeOnMars = "lifeOnMars",
  SteamSuperpowers = "steamSuperpowers",
  YoungEngineers = "youngEngineers",
}

type SteamFeesList = {
  id: number;
  name: SteamFees;
  amount: number;
}[];

const mapSteamFeesToTableData = (steamFeesList: InitialSteamFees, pickedFees: PickedSteamFees) => {
  return steamFeesList.map(({ name, id }) => ({
    name,
    key: name,
    id,
    amount: pickedFees.find(pickedFee => pickedFee.steamProgramId === id)?.amount ?? 0,
  }));
};

const SteamFeesTable = ({ schoolId }: { schoolId: number }) => {
  const { data: steamFeesData, revalidate } = useSWR<PickedSteamFees>(getSteamFeesList(schoolId));
  const { data: initialSteamFees } = useSWR<InitialSteamFees>(getInitialSteamFees());

  const [form] = useForm();
  const [editingKey, setEditingKey] = useState<string | null>(null);

  const [editFieldData, setEditFieldData] = useState<{ name: string; amount: number; id: number } | null>(
    null
  );

  const updateSteamFee = async ({ amount, steamProgramId }: { amount: number; steamProgramId: number }) => {
    try {
      await axiosAuth.put(getUpdateSteamFeeUrl(schoolId, steamProgramId), {
        amount,
      });
      message.success("Fee updated successfully");
      revalidate();
    } catch (e) {
      if (isErrorInfo(e)) {
        message.error(e?.response?.data?.message);
      } else {
        message.error("Something went wrong");
      }
    }
  };

  const handleFeeChange = async () => {
    try {
      if (editFieldData === null) return;
      const { amount, id } = editFieldData;
      setEditingKey(null);
      setEditFieldData(null);
      await updateSteamFee({ steamProgramId: id, amount });
    } catch (e) {
      setEditingKey(null);
      setEditFieldData(null);
      message.error("Something went wrong, please try again later");
    }
  };

  const data = mapSteamFeesToTableData(initialSteamFees ?? [], steamFeesData ?? []);

  return (
    <Form form={form} component={false}>
      <Table dataSource={data}>
        <Table.Column dataIndex="name" title="Name" width="28%" />
        <Table.Column
          dataIndex="amount"
          title="Amount"
          width="28%"
          render={(val, record: SteamFeesList[0]) => {
            if (record.name !== editingKey) return val;
            return (
              <Row style={{ display: "flex", flexDirection: "row" }}>
                <InputNumber
                  onChange={value =>
                    setEditFieldData(prev =>
                      prev !== null && value !== null ? { ...prev, amount: value } : null
                    )
                  }
                  value={editFieldData?.amount}
                  itemType="number"
                  min={1}
                />

                <Button
                  htmlType="button"
                  onClick={handleFeeChange}
                  type="primary"
                  color="#BDD000"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                    marginLeft: 8,
                  }}
                >
                  Save
                </Button>
              </Row>
            );
          }}
        />
        <Table.Column
          align="right"
          width="10%"
          render={(record: SteamFeesList[0]) => {
            const isEditing = record.name === editingKey;

            if (isEditing) return null;

            return (
              <div>
                <Button
                  type="text"
                  icon={<EditTwoTone twoToneColor="#00A1E1" />}
                  disabled={editingKey !== null}
                  onClick={() => {
                    setEditFieldData({ name: record.name, amount: record.amount, id: record.id });
                    setEditingKey(record.name);
                  }}
                />
              </div>
            );
          }}
        />
      </Table>
    </Form>
  );
};

export default SteamFeesTable;
