import { Button, Space, Table, Typography } from "antd";
import { DeleteButton, Spacer } from "components";
import { EditTwoTone, UnorderedListOutlined, PlusOutlined } from "@ant-design/icons";
import { FC, useMemo, useState } from "react";
import useSWR from "swr";
import SubCategoryModal from "./SubCategoryModal";
import { axiosAuth } from "helpers";

const { Column } = Table;
const { Title } = Typography;

type Props = {
  onDetailsClick: (subCategory: DevelopmentSubCategoryType) => void;
  subCategoryId?: number;
};

export interface DevelopmentSubCategoryType {
  id: number;
  categoryId: number;
  rank: number;
  badgePartialIcon: string;
  badgePartialImage: string;
  badgeCompleteIcon: string;
  badgeCompleteImage: string;
  status: number;
  createdAt: number;
  createdBy: number;
  updatedAt: number;
  updatedBy: number;
  developmentSubCategoryLang: DevelopmentSubCategoryLang[];
  name: string;
}

export interface DevelopmentSubCategoryLang {
  id: number;
  developmentSubCategoryId: number;
  lang: string;
  name: string;
  badgePartialTitle: string;
  badgePartialDescription: string;
  badgeCompleteTitle: string;
  badgeCompleteDescription: string;
}

const DevelopmentCategory: FC<Props> = ({ onDetailsClick, subCategoryId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<DevelopmentSubCategoryType | null>(null);

  const { data, revalidate } = useSWR<DevelopmentSubCategoryType[]>(
    subCategoryId ? `development-sub-category/${subCategoryId}` : null
  );

  const normalizedData = useMemo(
    () =>
      data?.map(item => ({
        ...item,
        name: item.developmentSubCategoryLang.find(({ lang }) => lang === "en")?.name,
      })),
    [data]
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          Skill Names
        </Title>
        <Button
          color="#BDD000"
          type="primary"
          style={{
            backgroundColor: "#BDD000",
            borderColor: "#BDD000",
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setInitialData(null);
            setTimeout(() => {
              setIsModalVisible(true);
            }, 0);
          }}
        >
          Add new
        </Button>
      </div>
      <Spacer />
      <Table dataSource={normalizedData} pagination={false}>
        <Column
          title="rank"
          key="rank"
          dataIndex="rank"
          render={(rank: string) => <Space size="middle">{rank}</Space>}
          width="10%"
        />
        <Column
          title="name"
          key="name"
          dataIndex="name"
          render={(name: string) => <Space size="middle">{name}</Space>}
          width="50%"
        />
        <Column
          title="status"
          key="status"
          dataIndex="status"
          render={(status: number) => <Space size="middle">{status === 10 ? "Active" : "Inactive"}</Space>}
          width="10%"
        />
        <Column
          title=""
          key="actions"
          dataIndex=""
          render={(dataItem: DevelopmentSubCategoryType) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignContent: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="text"
                  icon={<EditTwoTone twoToneColor="#00A1E1" />}
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setInitialData({
                      ...dataItem,
                      //Reset table name for other languages
                      name: "",
                    });
                    setTimeout(() => {
                      setIsModalVisible(true);
                    }, 0);
                  }}
                />
                <Button
                  type="text"
                  icon={<UnorderedListOutlined style={{ color: "#00A1E1" }} />}
                  onClick={() => onDetailsClick(dataItem)}
                  style={{ marginRight: "10px" }}
                />
                <DeleteButton
                  onSubmit={async () => {
                    await axiosAuth.delete(`development-sub-category/${dataItem.id}`);
                    await revalidate();
                  }}
                  type="icon"
                />
              </div>
            );
          }}
          width="10%"
        />
      </Table>
      <Spacer size={40} />
      <SubCategoryModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        revalidate={revalidate}
        subCategoryId={subCategoryId}
      />
    </>
  );
};

export default DevelopmentCategory;
