import {
  Button,
  Form,
  Typography,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Divider,
  Switch,
  message,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { UploadFile } from "antd/lib/upload/interface";
import {
  AddCamps,
  AddTerms,
  CampTime,
  Carer,
  CompletedEvents,
  CurrentEvents,
  EditPhoto,
  Notes,
  PendingEvents,
  TermTime,
} from "./components";
import styled from "styled-components";
import { CustomLabel, DeleteButton, ProgressReports, Spacer } from "components";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { ClassType, Kid } from "screens/Kids/Kids";
import { axiosAuth } from "helpers";
import Documents from "components/Documents";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ErrorMessage, ViewType } from "enums";
import getImageBase64 from "helpers/getImageBase64";
import { kidDocumentTypesList } from "const";
import { useAppSelector } from "store";

const { Title } = Typography;
const { Option } = Select;

export interface FormValues {
  firstName: string;
  lastName: string;
  birthCountryId: number;
  countryId: number;
  campTimeId: number;
  schoolId: number;
  languageId: number;
  religionId: number;
  dateOfBirth: any;
  gender: number;
  carer: number[];
  termId: number[];
  timeId: number;
  groupId: number;
  status: number;
  image: string;
  isDeleted: boolean;
  note: string;
  knownMedicalProblems: number;
  knownMedicalProblemsDetails: string;
  previousIllnesses: string;
  previousIllnessesDetails: string;
  seriousAccidents: string;
  personalityTraits: string;
  medication: string;
  authorizedForDisburse: number;
  scheduleDisbursingMedication: string;
  otherSpecialNeeds: string;
  newImage: UploadFile<any>[];
}

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string; kidId?: string }, any, { preselectedCarerId: number[] | undefined }>;

const AddKid = (props: Props) => {
  const { deleteSchoolUsers, isNurse } = useAppSelector(store => store.user.access);

  const [isChildUpdatePending, setIsChildUpdatePending] = useState(false);
  const history = useHistory();
  const preselectedCarers = props.location.state?.preselectedCarerId;
  const selectedKidId = props.match.params.kidId;
  const schoolId = props.match.params.id;

  const {
    data: kid,
    error: kidError,
    revalidate,
  } = useSWR<Kid>(selectedKidId ? `child/config/${selectedKidId}` : null);

  const [form] = Form.useForm<FormValues>();
  const [isEditMode, setIsEditMode] = useState(!selectedKidId);
  const title = kid ? `Child - ${kid.firstName} ${kid.lastName}` : "Add new child";
  const firstAttendance = kid && kid.firstAttendance ? `First attendance - ${kid.firstAttendance}` : null;
  const uniqueIdentifier = kid && kid.uniqueIdentifier ? `Unique identifier - ${kid.uniqueIdentifier}` : null;

  const initialValues: IInitialValues = {
    isDeleted: false,
    image: kid?.image,
    birthCountryId: kid?.birthCountryId,
    firstName: kid?.firstName,
    lastName: kid?.lastName,
    countryId: kid?.countryId,
    languageId: kid?.languageId,
    gender: kid?.gender,
    religionId: kid?.religionId,
    carer: preselectedCarers ?? kid?.childPerson?.map(item => item.personId),
    dateOfBirth: kid?.dateOfBirth ? dayjs(kid.dateOfBirth) : null,
    termId: kid?.childTerms?.map(item => {
      return item.id;
    }),
    campsId: kid?.childCamps?.map(item => {
      return item.id;
    }),
    campTimeId: kid?.campTimeId,
    groupId: kid?.groupId,
    timeId: kid?.timeId,
    status: kid?.status ?? 10,
  };

  const { data: countries } = useSWR<any[]>(`country`);

  const { data: languages } = useSWR<any[]>(`language`);

  const { data: religions } = useSWR<any[]>(`religion`);

  const { data: termsData } = useSWR<ITermsData[]>(`terms-v2/school/${schoolId}/future-terms`);

  const { data: campsData } = useSWR<ICampsData[]>(`camps/school/${schoolId}/future-camps`);

  const { data: termTimeData } = useSWR<ITimeData[]>(`school/${schoolId}/times/active`);

  const { data: campTimeData } = useSWR<ITimeData[]>(`school/${schoolId}/camps-time/active`);

  const { data: classesData } = useSWR<ClassType>(`school/classes/${schoolId}`);

  const { data: carersData } = useSWR<any[]>(`school/carers/${schoolId}`);

  const carersList = useMemo(
    () =>
      carersData?.map(
        item =>
          ({
            value: item.element.id,
            label: `${item.element.firstName} ${item.element.lastName}`,
          } ?? [])
      ),
    [carersData]
  );

  const countriesList = useMemo(
    () => countries?.map(item => ({ label: item.title, value: item.id } ?? [])),
    [countries]
  );

  const languagesList = useMemo(
    () => languages?.map(item => ({ label: item.title, value: item.id } ?? [])),
    [languages]
  );
  const religionsList = useMemo(
    () => religions?.map(item => ({ label: item.title, value: item.id } ?? [])),
    [religions]
  );

  const isTermOnGoingAndSelected = useCallback(
    (item: ITermsData) => {
      return initialValues.termId && initialValues?.termId.find(id => id === item.id && item.isTermOngoing);
    },
    [initialValues.termId]
  );

  const termsList = useMemo(
    () =>
      termsData?.map(
        item =>
          ({
            label: item.name,
            value: item.id,
            disabled: isTermOnGoingAndSelected(item),
          } ?? [])
      ),
    [termsData, isTermOnGoingAndSelected]
  );

  const campsList = useMemo(
    () => campsData?.map(item => ({ label: item.name, value: item.id } ?? [])),
    [campsData]
  );

  const termTimesList = useMemo(
    () => termTimeData?.map(item => ({ label: item.name, value: item.id, type: item.type })),
    [termTimeData]
  );

  const campTimesList = useMemo(
    () => campTimeData?.map(item => ({ label: item.name, value: item.id, type: item.type })),
    [campTimeData]
  );

  const classesList = useMemo(
    () => classesData?.group?.map((item: any) => ({ label: item.name, value: item.id } ?? [])),
    [classesData]
  );

  const currentClass = useMemo(
    () => classesList?.find(({ value }) => value === kid?.groupId),
    [classesList, kid?.groupId]
  );
  const currentTime = useMemo(
    () => termTimesList?.find(({ value }) => value === kid?.timeId),
    [termTimesList, kid?.timeId]
  );

  const onFinish = async (values: FormValues) => {
    setIsChildUpdatePending(true);
    let image;
    if (values.newImage) {
      image = await getImageBase64(values.newImage);
    }
    try {
      if (selectedKidId) {
        const {
          newImage,
          campTimeId,
          religionId,
          timeId,
          gender,
          dateOfBirth,
          birthCountryId,
          groupId,
          ...rest
        } = values;

        //We need to parse undefined fields to null
        const parseData = {
          ...rest,
          dateOfBirth: dateOfBirth ? dateOfBirth : null,
          gender: gender ? gender : null,
          religionId: religionId ? religionId : null,
          birthCountryId: birthCountryId ? birthCountryId : null,
          groupId: groupId ? groupId : null,
          campTimeId: campTimeId ? campTimeId : null,
          timeId: timeId ? timeId : null,
          schoolId: Number(schoolId),
          image,
        };

        await axiosAuth.post(`child/update/${selectedKidId}`, {
          ...parseData,
        });

        message.success("Child was succesfully updated");
        setIsEditMode(false);
      } else {
        const { dateOfBirth, newImage, ...rest } = values;
        await axiosAuth.post(`child/create/${schoolId}`, {
          ...rest,
          image,
          schoolId: Number(schoolId),
          dateOfBirth: dateOfBirth?.toDate(),
        });

        message.success("Child was succesfully created");
        history.goBack();
      }

      await revalidate();
      setIsChildUpdatePending(false);
    } catch (error) {
      message.error("Something went wrong");
      setIsChildUpdatePending(false);
    } finally {
      setIsChildUpdatePending(false);
    }
  };

  const initialValuesString = JSON.stringify(initialValues);

  useEffect(() => {
    form.resetFields();
  }, [initialValuesString, form]);

  const selectEditModeProps = {
    showArrow: isEditMode,
    bordered: isEditMode,
    disabled: !isEditMode,
  };

  return (
    <>
      <Form
        name="form_1"
        form={form}
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          overflow: "hidden",
          boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
          padding: "24px",
          paddingBottom: "0px",
        }}
        initialValues={{ ...initialValues }}
        onFinish={onFinish}
      >
        <Row>
          <Col flex={1}>
            <Title
              level={4}
              style={{
                color: "#E50A5C",
                margin: "0px",
                textAlign: "center",
              }}
            >
              {title}
            </Title>
            <FirstAttendanceContainer>
              <span>{firstAttendance}</span>
              <span>{uniqueIdentifier}</span>
            </FirstAttendanceContainer>
          </Col>

          <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
            {({ getFieldValue, setFieldsValue }) => {
              return (
                <Form.Item name="status">
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Active"
                    onChange={val => setFieldsValue({ status: val ? 10 : 0 })}
                    checked={getFieldValue("status") === 10}
                    disabled={!isEditMode}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Row>
        {selectedKidId && !isNurse && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <Switch
              checkedChildren="Edit mode"
              unCheckedChildren="Edit mode"
              defaultChecked
              onChange={val => setIsEditMode(val)}
              checked={isEditMode}
            />
            <Spacer />
          </div>
        )}

        <Row gutter={[24, 0]}>
          <Col span={6}>
            <Form.Item
              name="firstName"
              labelCol={{ span: 24 }}
              label={<CustomLabel>First name</CustomLabel>}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Input
                disabled={!isEditMode}
                style={
                  isEditMode
                    ? {}
                    : {
                        border: "none",
                        backgroundColor: "white",
                        padding: "4px 11px 4px 0px",
                      }
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="lastName"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Last name</CustomLabel>}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Input
                disabled={!isEditMode}
                style={
                  isEditMode
                    ? {}
                    : {
                        border: "none",
                        backgroundColor: "white",
                        padding: "4px 11px 4px 0px",
                      }
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="countryId"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Residence country</CustomLabel>}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Select
                showSearch
                allowClear
                options={countriesList}
                filterOption={(input, option) =>
                  (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...selectEditModeProps}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="languageId"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Language</CustomLabel>}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Select
                showSearch
                allowClear
                options={languagesList}
                filterOption={(input, option) =>
                  (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...selectEditModeProps}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={6}>
            <Form.Item
              name="dateOfBirth"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Date of birth</CustomLabel>}
            >
              <DatePicker onChange={e => {}} style={{ width: "100%" }} {...selectEditModeProps} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="gender" labelCol={{ span: 24 }} label={<CustomLabel>Gender</CustomLabel>}>
              <Select allowClear {...selectEditModeProps}>
                <Option value={10}>Male</Option>
                <Option value={20}>Female</Option>
                <Option value={0}>Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="religionId" labelCol={{ span: 24 }} label={<CustomLabel>Religion</CustomLabel>}>
              <Select
                allowClear
                options={religionsList}
                filterOption={(input, option) =>
                  (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...selectEditModeProps}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="birthCountryId"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Birth country</CustomLabel>}
            >
              <Select
                showSearch
                allowClear
                options={countriesList}
                filterOption={(input, option) =>
                  (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...selectEditModeProps}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col span={6}>
            <Form.Item name="groupId" labelCol={{ span: 24 }} label={<CustomLabel>Class</CustomLabel>}>
              <Select
                showSearch
                allowClear
                options={classesList}
                filterOption={(input, option) =>
                  (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...selectEditModeProps}
              />
            </Form.Item>
          </Col>
          <TermTime termTimesList={termTimesList} selectEditModeProps={selectEditModeProps} />
          <CampTime campTimesList={campTimesList} selectEditModeProps={selectEditModeProps} />
        </Row>
        <Row gutter={[24, 0]}>
          <Carer
            isEditMode={isEditMode}
            selectEditModeProps={selectEditModeProps}
            carersList={carersList}
            kid={kid}
            selectedKidId={selectedKidId}
          />
          <AddTerms termsList={termsList} disabled={!isEditMode} />
          <AddCamps campsList={campsList} disabled={!isEditMode} />
          <EditPhoto isEditMode={isEditMode} selectedKidId={selectedKidId} />
        </Row>
        {isEditMode && (
          <>
            <Divider style={{ margin: "10px 0px", marginBottom: "20px" }} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Form.Item>
                  <Button
                    loading={isChildUpdatePending}
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </Form>

      {!!kid && (
        <>
          <Spacer size={40} />
          <CurrentEvents
            currentClass={currentClass}
            currentTime={currentTime}
            currentTerm={kid?.childTermsAndCamps.currentTerm}
          />
          <Spacer size={40} />
          <CompletedEvents completedEvents={kid.childTermsAndCamps.completed} />
          <PendingEvents pendingEvents={kid.childTermsAndCamps.pending} />
          <Spacer size={40} />
          <Notes
            initialValues={initialValues}
            kid={kid}
            revalidate={revalidate}
            selectedKidId={selectedKidId}
          />
          <Spacer size={40} />
          <Documents
            documentsList={kid?.document ?? []}
            documentsTypes={kidDocumentTypesList}
            documentsTypesSelect={kidDocumentTypesList}
            uploadId={kid?.id ?? 0}
            userType="child"
            afterSubmit={revalidate}
          />
          <Spacer size={40} />
        </>
      )}
      {!!kid && (
        <>
          <ProgressReports kidId={kid?.id} />
          <Spacer size={40} />
        </>
      )}
    </>
  );
};

export default AddKid;

const FirstAttendanceContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export interface IInitialValues {
  birthCountryId?: number;
  isDeleted: boolean;
  image: any;
  firstName?: string;
  lastName?: string;
  countryId?: number;
  languageId?: number;
  gender?: number;
  religionId?: number;
  carer?: number[];
  dateOfBirth: Dayjs | null;
  termId?: number[];
  campsId?: number[];
  groupId?: number | null;
  timeId?: number;
  campTimeId?: number;
  status?: number;
}

export interface ITimeData {
  id: number;
  schoolId: number;
  name: string;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
  timeStart: string;
  timeEnd: string;
  status: number;
  createdAt: number;
  createdBy: number;
  updatedAt: number;
  updatedBy: number;
  type: number;
}

export interface ITermsData {
  id: number;
  isTermOngoing: boolean;
  name: string;
  startAt: string;
  endAt: string;
  units: [
    {
      id: number;
      weekNumber: number;
      unitName: string;
      name: string;
    }
  ];
  schoolAcademicYear: {};
}

export interface ICampsData {
  id: number;
  name: string;
  startAt: string;
  endAt: string;
  units: [
    {
      id: number;
      weekNumber: number;
      unitName: string;
      name: string;
    }
  ];
  schoolAcademicYear: {};
}
