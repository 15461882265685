import Title from "antd/lib/typography/Title";
import { SteamFeesTable } from "./components";
import { ViewType } from "enums";
import { RouteComponentProps } from "react-router-dom";

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

const SteamFees = (props: Props) => {
  const schoolId = props.match.params.id;

  return (
    <div>
      <Title
        level={3}
        style={{
          color: "#E50A5C",
          paddingBottom: "0px",
          marginBottom: "0px",
          backgroundColor: "#FFF",
          textAlign: "center",
          paddingBlock: 28,
          position: "relative",
        }}
      >
        Steam Fees
      </Title>
      <SteamFeesTable schoolId={Number(schoolId)} />
    </div>
  );
};

export default SteamFees;
