import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { EmployeeRole, StorageKeys } from "enums";
import { axiosAuth } from "helpers";

type AccessState = boolean | null;
type Access = {
  mainPanelAdd: AccessState;
  mainPanelSettings: AccessState;
  mainPanelPartnerAdd: AccessState;
  mainPanelPartnersList: AccessState;
  mainPanelSchoolAdd: AccessState;
  schoolDelete: AccessState;
  deleteSchoolUsers: AccessState;
  isNurse: AccessState;
  mainFinancialsPanel: AccessState;
};
interface UserState {
  role: EmployeeRole | null;
  basicSchoolId: number | null;
  error: boolean;
  isInitialSetupPending: boolean;
  access: Access;
}

const initialAccess: Access = {
  mainPanelAdd: null,
  mainPanelSettings: null,
  mainPanelPartnerAdd: null,
  mainPanelPartnersList: null,
  mainPanelSchoolAdd: null,
  schoolDelete: null,
  deleteSchoolUsers: null,
  isNurse: null,
  mainFinancialsPanel: null,
};

const initialState: UserState = {
  role: null,
  basicSchoolId: null,
  isInitialSetupPending: true,
  error: false,
  access: initialAccess,
};

export const fetchAccountData: any = createAsyncThunk("user/initialSetup", async () => {
  const authToken = localStorage.getItem(StorageKeys.AUTH_TOKEN);
  if (!authToken) {
    return false;
  }
  const userData = (await axiosAuth.get("user/me")).data;
  const schools = (await axiosAuth.get("school")).data;
  if (!schools || !schools.length) {
    message.error("Sorry, this account isnt connected with any school");
  }
  const basicSchoolId = schools[0].id;
  return {
    user: userData,
    basicSchoolId,
  };
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: store => {
      localStorage.removeItem(StorageKeys.AUTH_TOKEN);
      localStorage.removeItem(StorageKeys.REFRESH_TOKEN_KEY);
      store.role = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAccountData.pending, (state, action) => {
      state.role = null;
      state.error = false;
    });
    builder.addCase(fetchAccountData.fulfilled, (state, { payload }) => {
      state.isInitialSetupPending = false;
      if (payload) {
        state.role = payload.user.role;
        state.basicSchoolId = payload.basicSchoolId;
        state.access = generateAccess(payload.user.role);
      } else {
        state.role = null;
        state.basicSchoolId = null;
        state.access = initialAccess;
        localStorage.removeItem(StorageKeys.AUTH_TOKEN);
        localStorage.removeItem(StorageKeys.REFRESH_TOKEN_KEY);
      }
    });
    builder.addCase(fetchAccountData.rejected, (state, action) => {
      localStorage.removeItem(StorageKeys.AUTH_TOKEN);
      localStorage.removeItem(StorageKeys.REFRESH_TOKEN_KEY);
      state.role = null;
      state.isInitialSetupPending = false;
      state.basicSchoolId = null;
    });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;

const generateAccess = (role: EmployeeRole): Access => {
  if (!role) {
    return initialAccess;
  }
  return {
    mainPanelAdd: [
      EmployeeRole.SUPER_ADMIN,
      EmployeeRole.TERRITORY_OWNER,
      EmployeeRole.TERRITORY_MANAGER,
    ].includes(role),
    mainFinancialsPanel: [
      EmployeeRole.SUPER_ADMIN,
      EmployeeRole.TERRITORY_OWNER,
      EmployeeRole.TERRITORY_MANAGER,
      EmployeeRole.PARTNER,
    ].includes(role),
    mainPanelSchoolAdd: [EmployeeRole.SUPER_ADMIN, EmployeeRole.TERRITORY_OWNER].includes(role),
    mainPanelPartnerAdd: [EmployeeRole.SUPER_ADMIN].includes(role),
    mainPanelPartnersList: [EmployeeRole.SUPER_ADMIN].includes(role),
    mainPanelSettings: [EmployeeRole.SUPER_ADMIN].includes(role),
    schoolDelete: [EmployeeRole.SUPER_ADMIN, EmployeeRole.TERRITORY_OWNER].includes(role),
    deleteSchoolUsers: [
      EmployeeRole.SUPER_ADMIN,
      EmployeeRole.TERRITORY_OWNER,
      EmployeeRole.TERRITORY_MANAGER,
      EmployeeRole.SCHOOL_MANAGER,
    ].includes(role),
    isNurse: [EmployeeRole.NURSE].includes(role),
  };
};
