import { Checkbox, Modal } from "antd";
import { useAntdModal } from "hooks";
import WarningIcon from "./warningIcon.png";
import Title from "antd/lib/typography/Title";
import { useHistory } from "react-router-dom";
import { Kid } from "screens/Kids/Kids";
import { useEffect } from "react";

const InformationInfo = ({
  label,
  isFilled,
  navigate,
}: {
  label: string;
  isFilled: boolean;
  navigate: () => void;
}) => (
  <div onClick={navigate} style={{ margin: "8px 0", cursor: "pointer" }}>
    <Checkbox checked={isFilled} style={{ marginRight: 8 }} />
    {label}
  </div>
);

const ChildCheckInformation = ({ kidData }: { kidData: Kid }) => {
  const schoolId = kidData?.schoolId;
  const kidId = kidData?.id;

  const { visible, closeModal, openModal } = useAntdModal();
  const history = useHistory();

  const terms = kidData?.childTermsAndCamps;

  const termTimeSet = !!kidData?.timeId;

  const classSet = !!kidData?.groupId;

  const carersSet = kidData?.childPerson.length > 0;

  const termsDataFilled = terms && !!(terms?.currentTerm?.name || terms?.pending.length > 0);

  const childHasCamps = kidData?.childCamps?.length > 0;

  const childCampTime = !!kidData?.campTimeId;

  const shouldShowCampsTime = childHasCamps && !childCampTime;

  const childStorageKey = `childModalShown-${schoolId}-${kidId}`;

  const childModalShown = localStorage.getItem(childStorageKey);

  useEffect(() => {
    const handleModalShown = () => {
      if (!kidId || !schoolId) return;
      if (!Boolean(childModalShown) || childModalShown === null) {
        localStorage.setItem(childStorageKey, "true");
        if (visible) return;
        openModal();
      }
    };
    handleModalShown();
  }, [childModalShown, openModal, kidId, childStorageKey, visible, schoolId]);

  if ((termsDataFilled && termTimeSet && classSet && carersSet && !shouldShowCampsTime) || !kidId) {
    return null;
  }

  const navigateTo = (path: string) => {
    history.replace(path);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginLeft: 8 }} onClick={openModal}>
        <p style={{ color: "red", fontSize: 16, marginRight: 8 }}>Child Setup Incorrect</p>
        <img src={WarningIcon} width={30} height={30} alt="warning" />
      </div>
      {visible && (
        <Modal
          open={visible}
          onOk={closeModal}
          onCancel={closeModal}
          title="Missing Financials in Child Set Up"
          cancelText="Close"
        >
          <div style={{ marginBottom: 8 }}>
            <Title level={5}>
              Child is not setup correctly. You can always come back to this page to see what is missing.
              <br /> You can click on each item below to go directly to the section where you need to take
              action.
            </Title>
            <p>Please check the following:</p>
          </div>
          <InformationInfo
            label={carersSet ? "Child has Carers assigned" : "Child has no Carers assigned"}
            isFilled={carersSet}
            navigate={() => navigateTo(`/main/schools/${schoolId}/kids/${kidId}/addKid`)}
          />
          <InformationInfo
            label={classSet ? "Class assigned" : "No Class assigned"}
            isFilled={classSet}
            navigate={() => navigateTo(`/main/schools/${schoolId}/kids/${kidId}/addKid`)}
          />
          <InformationInfo
            label={termsDataFilled ? "Term assigned" : "No Term assigned"}
            isFilled={termsDataFilled}
            navigate={() => navigateTo(`/main/schools/${schoolId}/kids/${kidId}/addKid`)}
          />
          <InformationInfo
            label={termTimeSet ? "Days & Times assigned" : "No Days & Times assigned"}
            isFilled={termTimeSet}
            navigate={() => navigateTo(`/main/schools/${schoolId}/kids/${kidId}/addKid`)}
          />
          {shouldShowCampsTime && (
            <InformationInfo
              label={childCampTime ? "Camps time assigned" : "No camps time assigned"}
              isFilled={childCampTime}
              navigate={() => navigateTo(`/main/schools/${schoolId}/kids/${kidId}/addKid`)}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default ChildCheckInformation;
