import { RadioChangeEvent, Typography } from "antd";
import styled from "styled-components";
import { ExportToCsv, SummaryFilter, SummaryInfo } from "../components";
import { SingleSchoolFinancialsTable } from "./components";
import useSWR from "swr";
import { ViewType } from "enums";
import { RouteComponentProps } from "react-router-dom";
import { NoDataInfo } from "screens/Financials/components";
import { SummaryFinancials, currentDate, dateFormat, firstDayOfMonth } from "../const";
import { useState } from "react";
import moment from "moment";

export interface IInvoiceSummary {
  refunds: number;
  revenue: number;
  overDueAmount: number;
}
export interface ISingleSchoolFinancialRecord {
  schoolId: number;
  childId: number;
  name: string;
  lastPayment: number;
  totalBalance: number;
  monthlyBalance: number;
  overDueAmount: number;
  overDueDays: number;
  //To check
  image: string;
  class: string;
}

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

export const FinancialsSingleSchool = ({ match }: Props) => {
  const schoolId = match.params.id;

  const [filterOption, setFilterOption] = useState<{ startDate: string; endDate: string }>({
    startDate: moment(firstDayOfMonth).format(dateFormat).toString(),
    endDate: moment(currentDate).format(dateFormat).toString(),
  });
  const { Title } = Typography;

  const queryParams = new URLSearchParams();
  queryParams.append("startDate", filterOption.startDate);
  queryParams.append("endDate", filterOption.endDate);

  const { data: invoiceSummary, revalidate: revalidateSummary } = useSWR<IInvoiceSummary>(
    `schools/${schoolId}/financials/revenues?${queryParams.toString()}`
  );
  const { data: invoiceData, revalidate } = useSWR<ISingleSchoolFinancialRecord[]>(
    `schools/${schoolId}/financials/revenues/list`
  );

  const { data: schoolData } = useSWR(schoolId ? `school/${schoolId}` : null);
  const schoolCurrency = schoolData?.currency;

  if (!invoiceData) {
    return (
      <NoDataContainer>
        <NoDataInfo title="No financial history data for this school" />
      </NoDataContainer>
    );
  }

  const { refunds, revenue, overDueAmount } = invoiceSummary ?? {};

  const summaryData = [
    { name: SummaryFinancials.REFUNDS, price: refunds },
    { name: SummaryFinancials.REVENUE, price: revenue },
    { name: SummaryFinancials.OVERDUE, price: overDueAmount },
    //TODO future fields
    // { name: "Monthly Revenue", price: 0 },
    // { name: "Overall Revenue", price: 0 },
  ];

  const handleChangeRangeDate = (dateArray: [string, string]) => {
    setFilterOption({ startDate: dateArray[0], endDate: dateArray[1] });
  };

  const revalidateBoth = async () => {
    return (await revalidate()) && (await revalidateSummary());
  };

  return (
    <>
      <InvoiceContainer>
        <Title level={3} style={{ textAlign: "left", color: "#E50A5C" }}>
          Summary
        </Title>
        <SummaryContainer>
          <AmountContainer>
            {summaryData.map(({ name, price }) => (
              <SummaryInfo title={name} price={price ? price : 0} key={name} />
            ))}
            <SummaryFilter handleChangeRangeDate={handleChangeRangeDate} />
          </AmountContainer>
          <ExportToCsv dataSource={invoiceData} fileTitle={"Invoice_summary.csv"} />
        </SummaryContainer>
      </InvoiceContainer>
      <SingleSchoolFinancialsTable
        dataSource={invoiceData}
        schoolId={schoolId}
        revalidate={revalidateBoth}
        schoolCurrency={schoolCurrency}
      />
    </>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: end;
`;

const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px;
  border-radius: 10px;
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
