import { Select } from "antd";
import Title from "antd/lib/typography/Title";
import kidImg from "img/placeholder/kid.png";
import { Kid } from "screens/Kids/Kids";
import WarningIcon from "../../warningIcon.png";

enum ChildFeeTab {
  Term = "TERM",
  Camp = "CAMP",
  Additional = "ADDITIONAL",
}

type Props = {
  kidData?: Kid;
  isVisible: boolean;
  title: string;
  selectData?: {
    value: number | null;
    options?: { value: number; label: string }[];
    onChange: (value: number) => void;
  };
  additionalInformation?: string;
};
const FeeHeader = ({ kidData, selectData, title, isVisible, additionalInformation }: Props) => {
  if (!isVisible) return null;
  return (
    <div
      style={{
        backgroundColor: "#FFF",
        padding: "20px 16px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <img src={kidData?.image || kidImg} width={130} height={130} alt="Kid " />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
            textAlign: "center",
          }}
        >
          {title}
        </Title>
        <Title
          level={5}
          style={{
            paddingBottom: "0px",
            marginBottom: "0px",
            textAlign: "center",
          }}
        >
          Child: {kidData?.firstName} {kidData?.lastName}
        </Title>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 16,
          }}
        >
          {selectData && selectData.value !== null && (
            <Select
              options={selectData.options}
              value={selectData.value}
              onChange={selectData.onChange}
              dropdownMatchSelectWidth={false}
            />
          )}
          {!!additionalInformation && (
            <div style={{ display: "flex", alignItems: "center", margin: "10px auto 0" }}>
              <img src={WarningIcon} width={24} height={24} alt="warning" />{" "}
              <Title level={5} style={{ color: "red", margin: 0, marginBlockStart: 0, marginBlockEnd: 0 }}>
                {additionalInformation}
              </Title>
            </div>
          )}
        </div>
      </div>
      <img
        src={kidData?.image}
        width={130}
        height={130}
        style={{ visibility: "hidden" }}
        alt="Child profile pict"
      />
    </div>
  );
};

export default FeeHeader;
