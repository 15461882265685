interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
      code?: number;
    };
  };
}

export const isErrorInfo = (errInfo: unknown): errInfo is ErrorResponse => {
  return (
    typeof errInfo === "object" &&
    errInfo !== null &&
    typeof (errInfo as ErrorResponse).response === "object" &&
    typeof (errInfo as ErrorResponse).response?.data === "object"
  );
};
