import { Spin, Row, Col } from "antd";
import { Spacer, PrivateRoute } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { RouteComponentProps, Switch, useRouteMatch } from "react-router-dom";
import { Summary, Fees, Payments } from "./index";
import styled from "styled-components";
import { ViewType } from "enums";
import { FinancialsTabsNavigation } from "./components";
import { useState } from "react";
import { Kid } from "screens/Kids/Kids";

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string; kidId?: string }>;

export type TabType = "summary" | "fees" | "payments";

export const Financials = ({ location }: Props) => {
  const [pickedTab, setPickedTab] = useState<TabType>("summary");
  const kidData = location.state as Kid;
  const isPending = !kidData;

  const { url, path } = useRouteMatch();

  const handleTabChange = (tab: TabType) => {
    setPickedTab(tab);
  };

  if (isPending) {
    return (
      <SpinContainer
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spacer size={40} />
        <Spin spinning />
      </SpinContainer>
    );
  }
  return (
    <FinancialContainer>
      <FinancialMenuContainer
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <FinancialsTabsNavigation
          kidData={kidData}
          url={url}
          handleTabChange={handleTabChange}
          pickedTab={pickedTab}
        />
      </FinancialMenuContainer>

      <Spacer />
      <Row align="top">
        <Col span={24}>
          <AnimatePresence>
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Switch>
                <PrivateRoute path={`${path}/summary`} component={Summary} />
                <PrivateRoute path={`${path}/fees`} component={Fees} />
                <PrivateRoute path={`${path}/payments`} component={Payments} />
              </Switch>
            </motion.div>
          </AnimatePresence>
        </Col>
      </Row>
    </FinancialContainer>
  );
};

const SpinContainer = styled.div`
  flex: 1;
  display: "flex";
  justify-content: "center";
  align-items: "center";
`;

const FinancialContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FinancialMenuContainer = styled.div`
  display: "flex";
  justify-content: "end";
`;
