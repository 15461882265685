import { Button, Input, Space, Spin, Table, Typography, message } from "antd";
import { Container, DeleteButton } from "components";
import { SearchOutlined, EditTwoTone } from "@ant-design/icons";
import { FC, useRef } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { DocumentType } from "screens/Documents/Documents";
import { axiosAuth } from "helpers";

const { Title } = Typography;

const { Column } = Table;

export interface SchoolType {
  id: number;
  partnerId: number;

  name: string;
  typeOfSchool: string;
  countryId: number;
  city: string;
  lang: string;
  timeZone: string;
  currency: string;
  registrationFee: string;
  annualFee: string;
  medicalFee: string;
  image: any;
  status: number;
  facebookLink: any;
  instagramLink: any;
  linkedinLink: any;
  maxCapacity: any;
  firstOpen?: string;
  termPerYear: any;
  createdAt: number;
  createdBy: number;
  updatedAt: number;
  updatedBy: number;
  supportAdmin: string;
}

export type PartnerType = {
  countryId: number;
  dateOfBirth: string;
  image: string;
  dateOfRenewal: string;
  notificationEmail: string;
  email: string;
  firstName: string;
  lastName: string;
  officePhone: string;
  phone: string;
  type: number;
  address: string;
  schools: SchoolType[];
  createdAt: number;
  createdBy: number;
  id: number;
  name: string;
  status: number;
  updatedAt: number;
  updatedBy: number;
  document?: DocumentType[];
};

const Partners: FC = () => {
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<PartnerType[]>("partner");
  const isPending = !data && !error;
  const normalizedData = data?.map(item => {
    const { status, ...rest } = item;
    const newItem = {
      ...rest,
      status: status === 10 ? "Active" : "Inactive",
    };
    return newItem;
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleDeletePartner = async (id: number) => {
    try {
      await axiosAuth.delete(`partner/${id}`);
      revalidate();
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  return (
    <>
      <Spin spinning={isPending}>
        <Container>
          <Table
            title={() => {
              return (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title
                    level={3}
                    style={{
                      color: "#E50A5C",
                      paddingBottom: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Partners
                  </Title>
                </div>
              );
            }}
            dataSource={normalizedData}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Name"
              key="name"
              dataIndex="name"
              render={(title: PartnerType["name"]) => <Space size="middle">{title}</Space>}
              width="60%"
              sorter={(a, b) => {
                return a.title.localeCompare(b.title);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("title")}
            />
            <Column
              onFilter={(value, record: any) => record.status.includes(value)}
              filters={[
                { text: "Active", value: "Active" },
                { text: "Inactive", value: "Inactive" },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="25%"
              render={(status: PartnerType["status"]) => <Space size="middle">{status}</Space>}
            />
            <Column
              title=""
              width="15%"
              render={data => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link
                      to={{ pathname: "addPartner", state: data }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <EditTwoTone twoToneColor="#00A1E1" />
                    </Link>
                    <DeleteButton onSubmit={() => handleDeletePartner(data.id)} type="icon" />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
    </>
  );
};

export default Partners;
