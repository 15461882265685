import { DatePicker, DatePickerProps, Divider, Typography, Image, message } from "antd";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ViewType } from "enums";
import { axiosAuth } from "helpers";
import In from "img/kidpresence/in.svg";
import Out from "img/kidpresence/out.svg";
import { getRegisterKidUrl, getUnregisterKidUrl } from "types/rest/kidsPresence";

const { Title } = Typography;
type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string; kidId?: string }>;

const Presence = (props: Props) => {
  const [selectedTime, setSelectedTime] = useState("");

  const onOk = (value: DatePickerProps["value"]) => {
    if (!value) return;
    setSelectedTime(value?.format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleRegisterPresence = async (status: "IN" | "OUT") => {
    const { id, kidId } = props.match.params;
    if (!id || !kidId) return;

    if (!selectedTime) {
      message.error("Select date and time");
      return;
    }

    try {
      await axiosAuth.post(status === "IN" ? getRegisterKidUrl(id, kidId) : getUnregisterKidUrl(id, kidId), {
        registerTime: selectedTime,
      });
      message.success("Day logs has been saved");
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setSelectedTime("");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "4px",
        overflow: "hidden",
        boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
        padding: "24px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          Presence
        </Title>
      </div>
      <Divider />
      <div
        style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
      >
        <div>
          <DatePicker showTime onOk={onOk} format="YYYY-MM-DD HH:mm" showNow={false} />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 50, gap: 50 }}>
          <div
            onClick={() => handleRegisterPresence("IN")}
            style={{ backgroundColor: "#009DDF", alignItems: "center", height: 70, display: "flex" }}
          >
            <Image preview={false} height={50} width={130} src={In} />
          </div>
          <div
            onClick={() => handleRegisterPresence("OUT")}
            style={{
              backgroundColor: "#E76A22",
              alignItems: "center",
              height: 70,
              display: "flex",
            }}
          >
            <Image preview={false} height={50} width={130} src={Out} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presence;
