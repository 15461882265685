export const getCreateNewChildInSchoolUrl = (schoolId: string) => `child/create/${schoolId}`;

export type CreateNewChildInSchoolBody = {
  firstName: string;
  lastName: string;
  schoolId: number;
  countryId: number;
  languageId: number;
  religionId: number;
  dateOfBirth: Date;
  gender: number;
  carer: number[];
  termId: number;
  timeId: number;
  classId: number;
  status: number;
  image: {
    name: string;
    type: string;
    content: string;
  };
};

export const getChildFinanceConfig = (schoolId: number, childId: number) =>
  `schools/${schoolId}/childs/${childId}/finance-config`;

export const getChildCampFinanceConfig = (schoolId: number, childId: number) =>
  `schools/${schoolId}/childs/${childId}/finance-camps-config`;

export type ChildFinanceConfig = {
  paymentType: "TERM" | "MONTH";
  oneTimeFee: boolean;
  annualFeeIds: number[];
  termFee: number[];
  campFeeIds: number[];
  foodFeeIds: number[];
  transportFeeIds: number[];
  materialFeeIds: number[];
  otherFeeIds: number[];
  discountIds: number[];
};

export const getChildFinanceTerms = (schoolId: number, childId: number) =>
  `schools/${schoolId}/childs/${childId}/available-statement-terms`;

export const getChildFinanceCamps = (schoolId: number, childId: number) =>
  `schools/${schoolId}/childs/${childId}/available-statement-camps`;

export type ChildFinanceCamp = {
  name: string;
  startDate: string;
  endDate: string;
  id: number;
};

export type ChildFinanceTerm = {
  name: string;
  id: number;
  startDate: string;
  endDate: string;
};

export const getUpdateChildTermStatementUrl = (schoolId: number, childId: number, pickedTerm: number) =>
  `schools/${schoolId}/childs/${childId}/term-statements/${pickedTerm}`;

export const getChildPreviewFinanceConfig = (schoolId: number, childId: number, pickedTerm: number) =>
  `schools/${schoolId}/childs/${childId}/finance-config-preview?termId=${pickedTerm}`;

export const getChildPreviewCampFinanceConfig = (schoolId: number, childId: number, pickedCamp: number) =>
  `schools/${schoolId}/childs/${childId}/finance-camps-config-preview?campId=${pickedCamp}`;

export type ChildFee = {
  id: number;
  name: string;
};

export type ChildCampPreviewFinance = {
  financeConfigLookups: {
    discounts: DiscountConfig[];
  };
  statementPreview: {
    camps: {
      fromDate: string;
      name: string;
      toDate: string;
    }[];
    items: [];
  };
};

export enum DiscountCategory {
  Other = "other",
  Sibling = "sibling",
  Corporate = "corporate",
}

export type DiscountConfig = {
  id: number;
  name: string;
  category: DiscountCategory;
};

export type ChildPreviewFinanceConfig = {
  financeConfig: ChildFinanceConfig;
  financeConfigLookups: {
    oneTimeFees: boolean;
    annualFees: ChildFee[];
    campFees: ChildFee[];
    discounts: DiscountConfig[];
    foodFees: ChildFee[];
    materialFees: ChildFee[];
    otherFees: ChildFee[];
    transportFees: ChildFee[];
  };
  statementPreview: {
    items: [];
    term?: {
      fromDate: string;
      toDate: string;
      name: string;
      termHasAssignedFee: boolean;
    } | null;
  };
};

export const getChildAdditionalFinanceConfigPreview = (schoolId: number, childId: number) =>
  `schools/${schoolId}/childs/${childId}/finance-additional-config-preview`;

export const getChildAdditionalFinanceConfig = (schoolId: number, childId: number) =>
  `schools/${schoolId}/childs/${childId}/finance-additional-config`;

export const getAddAdditionalFeeToStatement = (schoolId: number, childId: number) =>
  `schools/${schoolId}/childs/${childId}/additional-statement`;
