import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import { ChildFee, ChildFinanceConfig } from "types/rest/child";
import { Checkbox, Col, Row } from "antd";
import { Spacer } from "components";
import { FeeContainer } from "../../styles";
import { useField } from "formik";
import { selectFeeId } from "../../helpers";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

type Props = {
  title: string;
  group: keyof ChildFinanceConfig | "steamFeeIds";
  setFieldValue: (field: string, value: number[], shouldValidate?: boolean | undefined) => void;
  data: ChildFee[];
};

const FeeConfigurationDetail = ({ title, data, setFieldValue, group }: Props) => {
  const [field] = useField(group);

  const handleChangeField = (e: CheckboxChangeEvent) => {
    const feeId: number = e.target.value;
    setFieldValue(group, selectFeeId(feeId, field.value));
  };

  if (data.length === 0) return null;

  return (
    <FeeContainer>
      <Title level={4} style={{ lineHeight: 1, marginBlockEnd: 0 }}>
        {title}
      </Title>
      <Spacer size={8} />
      {data?.map(item => (
        <Row align="middle" style={{ marginBottom: 8 }}>
          <Col span={2}>
            <Checkbox
              style={{ top: 0 }}
              value={item.id}
              onChange={handleChangeField}
              checked={field.value?.includes(item.id)}
            />
          </Col>
          <Col span={22}>
            <Text style={{ fontSize: "1.2rem" }}>{item.name}</Text>
          </Col>
        </Row>
      ))}
    </FeeContainer>
  );
};

export default FeeConfigurationDetail;
