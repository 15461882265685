import { FC, useEffect, useMemo, useState } from "react";
import { Button, Form, Input, Select, Spin, Row, Col, Typography, Pagination, Image, message } from "antd";
import { Collapse } from "react-collapse";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import useSWR from "swr";
import { Link, RouteComponentProps } from "react-router-dom";
import { Container, CustomBgImage, Spacer } from "components";
import { Language } from "screens/Languages/Languages";
import { employeeRoles, employeeRolesList } from "const/employeeRoles";
import { ErrorMessage, ViewType } from "enums";
import {
  getSearchStaffFromSpecificSchoolUrl,
  getSearchStaffUrl,
  getStaffFromSpecificSchoolUrl,
  getStaffListUrl,
  SearchStaffBody,
  StaffListRes,
} from "types/rest/staff";
import { CountryType, StaffType } from "types";
import { axiosAuth } from "helpers/axios";
import { SchoolType } from "screens/Schools/Schools";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const numEachPage = 25;

const { Title, Text } = Typography;

type SearchValues = {
  firstName: string | undefined;
  lastName: string | undefined;
  country: number[] | undefined;
  language: number[] | undefined;
  role: number[] | undefined;
  status: number;
};

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id: string | undefined }>;

const { Option } = Select;

const Staff: FC<Props> = ({ match: { params }, viewType }) => {
  const [isSearchPending, setIsSearchPending] = useState(false);
  const [searchData, setSearchData] = useState<StaffType[] | null>(null);
  const [pagination, setPagination] = useState({
    minValue: 0,
    maxValue: numEachPage,
  });

  const getStaffUrls = () => {
    if (viewType === ViewType.MAIN_PANEL) {
      return {
        staffListUrl: getStaffListUrl(),
        staffQueryUrl: getSearchStaffUrl(),
      };
    }
    if (viewType === ViewType.SCHOOL_DETAILS && params.id) {
      return {
        staffListUrl: getStaffFromSpecificSchoolUrl(params.id),
        staffQueryUrl: getSearchStaffFromSpecificSchoolUrl(params.id),
      };
    }
    return {
      staffListUrl: null,
      staffQueryUrl: null,
    };
  };

  const { staffListUrl, staffQueryUrl } = getStaffUrls();

  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);
  const { data, error, mutate } = useSWR<StaffListRes>(staffListUrl);

  const { data: countries, error: countriesError } = useSWR<CountryType[]>(`country`);

  const isCountriesPending = !countries && !countriesError;

  const { data: languages, error: laguagesError } = useSWR<Language[]>(`language`);

  const isLanguagesPending = !languages && !laguagesError;

  const { data: schoolData, error: schoolError } = useSWR<SchoolType[]>("school");
  const isSchoolPending = !schoolData && !schoolError;

  const isPending =
    (!data && !error) || isCountriesPending || isLanguagesPending || isSchoolPending || isSearchPending;

  const screens = useBreakpoint();
  const countriesList = useMemo(
    () => countries?.map(item => ({ label: item.title, value: item.id } ?? [])),
    [countries]
  );

  const languagesList = useMemo(
    () => languages?.map(item => ({ label: item.title, value: item.id } ?? [])),
    [languages]
  );

  const schoolsList = useMemo(
    () => schoolData?.map((item: any) => ({ label: item.name, value: item.id } ?? [])),
    [schoolData]
  );

  const onPaginationChange = (value: number, pageSize: number | undefined) => {
    const currentPageSize = pageSize || numEachPage;
    setPagination({
      minValue: value !== 0 ? (value - 1) * currentPageSize : 0,
      maxValue: value !== 0 ? value * currentPageSize : currentPageSize,
    });
  };

  const onSearch = async (values: SearchValues) => {
    try {
      setIsSearchPending(true);
      if (staffQueryUrl) {
        const body: SearchStaffBody = values;
        const data = (await axiosAuth.post(staffQueryUrl, body)).data;
        setSearchData(data);
      }
      setIsSearchPending(false);
    } catch (e) {
      message.error(ErrorMessage.UNEXPECTED);
      setIsSearchPending(false);
    }
  };

  useEffect(() => {
    setPagination({ minValue: 0, maxValue: numEachPage });
  }, [JSON.stringify(searchData)]);

  const validData = searchData ?? data;
  return (
    <Container>
      <div>
        <Form
          name="basic"
          onFinish={onSearch}
          autoComplete="off"
          onFinishFailed={() => {}}
          style={{
            backgroundColor: "white",
            cursor: "pointer",
            borderRadius: "4px",
            overflow: "hidden",
            boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
            padding: "24px 24px 0px 24px",
          }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                name="firstName"
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="lastName"
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
            <Col span={6} offset={6}>
              <Form.Item name="status">
                <Select placeholder="Status" allowClear>
                  <Option value={10}>Active</Option>
                  <Option value={0}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]} align="middle" justify="space-between">
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  color="red"
                  style={{
                    backgroundColor: "#BDD000",
                    outlineColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
                onClick={() => {
                  setIsFilterCollapsed(prev => !prev);
                }}
              >
                <p style={{ color: "#a9a9a9", marginRight: "10px" }}>More filters</p>
                {isFilterCollapsed ? (
                  <UpOutlined style={{ color: "#a9a9a9" }} />
                ) : (
                  <DownOutlined style={{ color: "#a9a9a9" }} />
                )}
              </div>
            </Col>
          </Row>
          <Collapse isOpened={isFilterCollapsed}>
            <Row gutter={[24, 0]}>
              <Col span={6}>
                <Form.Item name="country">
                  <Select
                    allowClear
                    showArrow
                    placeholder="Country"
                    mode="multiple"
                    options={countriesList}
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="language">
                  <Select
                    allowClear
                    showArrow
                    placeholder="Language"
                    mode="multiple"
                    options={languagesList}
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="role">
                  <Select
                    allowClear
                    showArrow
                    placeholder="Role"
                    mode="multiple"
                    options={employeeRolesList}
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              {viewType === ViewType.MAIN_PANEL && (
                <Col span={6}>
                  <Form.Item name="school">
                    <Select allowClear placeholder="school" mode="multiple" showArrow options={schoolsList} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Collapse>
        </Form>
        <Spacer size={48} />
        <Spin spinning={isPending}>
          <Row gutter={[48, 48]} justify="start" align="stretch">
            {validData &&
              validData.slice(pagination.minValue, pagination.maxValue).map(item =>
                !item ? null : (
                  <Col style={{ width: screens.xxl ? "20%" : "25%" }} key={item?.id}>
                    <Link to={{ pathname: `addStaff/${item?.id}` }}>
                      <div
                        style={{
                          backgroundColor: "white",
                          cursor: "pointer",
                          height: "100%",
                          borderRadius: "4px",
                          overflow: "hidden",
                          boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.03)",
                          opacity: item.status === 10 ? 1 : 0.5,
                          position: "relative",
                        }}
                      >
                        <CustomBgImage type="carer" src={item.image} />
                        {item.evaluation && (
                          <div
                            style={{
                              position: "absolute",
                              top: 10,
                              left: 10,
                              color: "white",
                              backgroundColor: "#e50a5c",
                              textAlign: "center",
                              display: "flex",
                              fontWeight: "700",
                              justifyContent: "center",
                              borderRadius: "50%",
                              height: 36,
                              width: 36,
                              alignItems: "center",
                              fontSize: 15,
                            }}
                          >
                            {item.evaluation}
                          </div>
                        )}

                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <Spacer size={5} />
                          <Title level={5} style={{ color: "#E50A5C", textAlign: "center" }}>
                            {`${item.firstName} ${item.lastName}`}
                          </Title>
                          <Text
                            style={{
                              fontSize: "1.3rem",
                              textAlign: "center",
                            }}
                          >
                            {employeeRoles[item.role]}
                          </Text>
                          {viewType === ViewType.MAIN_PANEL && (
                            <>
                              <Spacer size={10} />
                              <Text
                                style={{
                                  fontSize: "1.3rem",
                                  textAlign: "center",
                                }}
                              >
                                {item.school?.name ?? "not set"}
                              </Text>
                            </>
                          )}
                          <Spacer size={10} />
                          {item.groupEmployee && viewType === ViewType.MAIN_PANEL && (
                            <>
                              {item.groupEmployee?.map(({ group }, index) => (
                                <Text
                                  style={{
                                    fontSize: "1.3rem",
                                    textAlign: "center",
                                    marginTop: index !== 0 ? "10px" : "0px",
                                  }}
                                >
                                  {group?.name ?? "not set"}
                                </Text>
                              ))}
                            </>
                          )}
                          {viewType !== ViewType.MAIN_PANEL && item.teacherGroup && (
                            <>
                              <Spacer size={10} />
                              {item.teacherGroup.map((item, index) => (
                                <Text
                                  style={{
                                    fontSize: "1.3rem",
                                    textAlign: "center",
                                    marginTop: index !== 0 ? "10px" : "0px",
                                  }}
                                >
                                  {item?.name}
                                </Text>
                              ))}
                            </>
                          )}
                        </div>
                        <Spacer />
                      </div>
                    </Link>
                  </Col>
                )
              )}
          </Row>
          <Spacer size={40} />
          <Row justify="end">
            <Col>
              <Pagination
                defaultPageSize={numEachPage}
                onChange={onPaginationChange}
                total={validData?.length ?? 0}
              />
            </Col>
          </Row>
        </Spin>
      </div>
      <Spacer size={100} />
    </Container>
  );
};

export default Staff;
