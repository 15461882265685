import { CustomLabel } from "components";
import styled from "styled-components";

interface Props {
  title: string;
  price: number;
}

export const SummaryInfo = ({ price, title }: Props) => {
  return (
    <SummaryInfoContainer>
      <CustomLabel>{title}</CustomLabel>
      <PriceContainer price={price}>{price}</PriceContainer>
    </SummaryInfoContainer>
  );
};

const SummaryInfoContainer = styled.div`
  margin-top: 10px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const PriceContainer = styled.div<{ price: number }>`
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  min-width: 140px;
  width: 100%;
  border-radius: 2px;
  color: ${({ price }) => (price > 0 ? "green" : "red")};
`;
